import darkBronze from './dark-bronze.jpg';
import midBronze from './mid-bronze.jpg';
import darkGrey from './dark-grey.jpg';
import lightGrey from './light-grey.jpg';
import white from './white.jpg';
import stone from './stone.jpg';

export default {
  'Mid Bronze': midBronze,
  'Light Grey': lightGrey,
  'Dark Grey': darkGrey,
  'Dark Bronze': darkBronze,
  White: white,
  Stone: stone,
};
