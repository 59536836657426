import styled from '@react-pdf/styled-components';
import { COLORS } from '../../constants';

export const BackgroundWrapper = styled.View`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const BackgroundImgWrapper = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  object-fit: cover;
`;
export const FooterWrapper = styled.View`
  position: absolute;
  top: 92vh;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FooterImgWrapper = styled.View`
  margin: 0 30px;

  height: 70px;
  width: 120px;
`;

export const ContentWrapper = styled.View`
  position: absolute;
  top: 40%;
  left: 45vw;
`;

export const Heading = styled.Text`
  font-size: 18pt;
  font-family: 'NeoSansLight';
  color: ${COLORS.green};

  margin-bottom: 20px;
`;

export const TwoCol = styled.View`
  display: flex;
  flex-direction: row;
`;

export const Col = styled.View`
  width: 200px;
`;

export const Item = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  font-family: 'MyriadPro';
  font-size: 9pt;
`;
