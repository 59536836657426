import React from 'react';
import { Page, Text, Image } from '@react-pdf/renderer';
import {
  GreenGraphicWrapper,
  BlueGraphicWrapper,
  ImageWrapper,
  FooterWrapper,
  FooterImgWrapper,
  Title,
  ContactCard,
  // ContactHeading,
  ContactItem,
  ContactItemAlt,
} from './coverPage.styles';

import GraphicGreen from '../../assets/Big_green_sapphire.png';
import GraphicBlue from '../../assets/Big_blue_sapphire.png';
import Image1 from '../../assets/coverPage.png';
import FooterLeftImg from '../../assets/footerLeft.png';
import FooterRightImg from '../../assets/footerRight.png';

const CoverPage = ({ data }) => {
  return (
    <Page size="A4" orientation="landscape">
      <GreenGraphicWrapper src={GraphicGreen} />
      <BlueGraphicWrapper src={GraphicBlue} />
      <ImageWrapper src={Image1} />
      <FooterWrapper>
        <FooterImgWrapper>
          <Image src={FooterLeftImg} />
        </FooterImgWrapper>
        <FooterImgWrapper>
          <Image src={FooterRightImg} />
        </FooterImgWrapper>
      </FooterWrapper>
      <Title>
        <Text>Balcony specification overview</Text>
      </Title>
      <ContactCard>
        <ContactItem>
          <Text style={{ width: '80px' }}>Date:</Text>
          <Text>{data.date || new Date().toLocaleDateString()}</Text>
        </ContactItem>
        <ContactItem>
          <Text style={{ width: '80px' }}>Company:</Text>
          <Text>{data.company}</Text>
        </ContactItem>
        <ContactItem>
          <Text style={{ width: '80px' }}>Project:</Text>
          <Text>{data.project || 'Custom configuration'}</Text>
        </ContactItem>
        <ContactItem>
          <Text style={{ width: '80px' }}>Prepared by:</Text>
          <Text>{data.name}</Text>
        </ContactItem>
        {/* {data.telephone && (
          <ContactItemAlt>
            <Text style={{ width: '50px' }}>T:</Text>
            <Text>{data.telephone}</Text>
          </ContactItemAlt>
        )} */}
        {data.email && (
          <ContactItem>
            <Text style={{ width: '80px' }}>E-mail:</Text>
            <Text>{data.email}</Text>
          </ContactItem>
        )}
      </ContactCard>
    </Page>
  );
};
export default CoverPage;
