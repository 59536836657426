import React from 'react';
import { Wrapper } from './app.styles';
import Player from './components/Player';
import Configurator from './components/Configurator';
import ViewSwitcher from './components/ViewSwitcher';

const App = () => {
  return (
    <>
      <Wrapper>
        <Configurator />
        <Player />
      </Wrapper>
      <ViewSwitcher />
    </>
  );
};

export default App;
