import styled from '@react-pdf/styled-components';
import { COLORS } from '../../constants';

export const Left = styled.View`
  width: 310px;
  margin-right: 10px;
`;

export const Right = styled.View`
  width: 310px;
`;

export const TableHeading = styled.View`
  display: flex;
  flex-direction: row;
  background: ${COLORS.green};
  padding: 5px;

  color: white;
  font-size: 8pt;
  font-family: 'MyriadProBold';
`;

export const TableRow = styled.View`
  display: flex;
  flex-direction: row;
  background: ${props =>
    props.background === 'light' ? COLORS.lightGray : COLORS.darkGray};
  padding: 5px;

  font-size: 8pt;
  font-family: 'MyriadPro';
`;
