import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import {
  SecondarySectionWrapper,
  SecondaryItem,
  SectionTitle as Title,
  SectionContent as Content,
  SecondaryContentItem as ContentItem,
  SecondaryContentItemTitle as ContentItemTitle,
  SecondaryContentSubitem as ContentSubitem,
} from './mobileConfigurator.styles';
import InputComponents from '../../InputComponents';
import Summary from '../../Summary';
import ICONS from '../../../assets/icons';

const SecondarySection = ({
  show,
  address,
  selected,
  onSelect,
  onClickBackground,
  configurator,
}) => {
  const state = useContext(AppContext);
  const parent = state.data[address[0]].sections[address[1]];

  const handleUpdateInput = (parent, section) => {
    if (state && state.configuration[parent.attribute] !== section.value) {
      if (section.validation) {
        const updateObj = section.override
          ? { ...section.override, [parent.attribute]: section.value }
          : { [parent.attribute]: section.value };
        const valid = section.validation(updateObj, state.configuration);
        if (!valid) return;
      }

      state.setAttribute(parent.attribute, section.value);
      if (
        section.override &&
        state.configuration[parent.attribute] !== section.value
      ) {
        for (const attribute in section.override) {
          state.setAttribute(attribute, section.override[attribute]);
        }
      }
    }
  };

  if (address[0] === 4)
    return (
      <SecondarySectionWrapper expand={show} onClick={onClickBackground}>
        <Summary />
      </SecondarySectionWrapper>
    );

  return (
    <SecondarySectionWrapper expand={show} onClick={onClickBackground}>
      {!!configurator &&
        (!!!configurator.length ? null : configurator.length > 1 ? (
          Object.values(configurator).map((section, i) => (
            <SecondaryItem
              key={i}
              onClick={e => {
                onSelect(i);
                e.stopPropagation();
              }}
              selected={selected === i}
            >
              <div>
                <div>
                  <div>
                    {section.icon ? (
                      <img src={ICONS[section.icon]} alt={section.icon} />
                    ) : (
                      section.label[0]
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div>{section.label}</div>
              </div>
            </SecondaryItem>
          ))
        ) : (
          <Content>
            <Title>{configurator[0].label}</Title>
            {Object.values(configurator[0].sections).map((section, i) => (
              <ContentItem
                key={i}
                selected={
                  !!(
                    state &&
                    section.value === state.configuration[parent.attribute]
                  )
                }
                onClick={() => handleUpdateInput(parent, section)}
              >
                <ContentItemTitle>
                  <div>{section.label}</div>
                </ContentItemTitle>
                {Object.values(section.inputs).map((input, j) => (
                  <ContentSubitem key={j}>
                    <InputComponents
                      key={j}
                      {...input}
                      configuration={state.configuration[input.attribute]}
                      onChange={value =>
                        state.setAttribute(input.attribute, value)
                      }
                    />
                  </ContentSubitem>
                ))}
              </ContentItem>
            ))}
          </Content>
        ))}
    </SecondarySectionWrapper>
  );
};

export default SecondarySection;
