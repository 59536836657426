import React from 'react';
import { Page, Image } from '@react-pdf/renderer';
import {
  BackgroundWrapper,
  BackgroundImgWrapper,
  FooterWrapper,
  FooterImgWrapper,
  SectionNumber,
  Heading,
  Content,
  Title,
  TwoCol,
} from './pageTemplate.styles';

import BackgroundImg from '../../assets/Big_green_sapphire3.png';
import FooterLeftImg from '../../assets/footerLeft.png';
import FooterRightImg from '../../assets/footerRight.png';

const PageTemplate = ({ number, title, children }) => {
  return (
    <Page size="A4" orientation="landscape">
      <BackgroundWrapper>
        <BackgroundImgWrapper>
          <Image src={BackgroundImg} />
        </BackgroundImgWrapper>
        <FooterWrapper>
          <FooterImgWrapper>
            <Image src={FooterLeftImg} />
          </FooterImgWrapper>
          <FooterImgWrapper>
            <Image src={FooterRightImg} />
          </FooterImgWrapper>
        </FooterWrapper>
      </BackgroundWrapper>
      <SectionNumber>
        <Heading>{number}</Heading>
      </SectionNumber>
      <Content>
        <Title>{title}</Title>
        <TwoCol>{children}</TwoCol>
      </Content>
    </Page>
  );
};

export default PageTemplate;
