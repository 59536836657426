import React from 'react';
import { Wrapper } from './player.styles';

const Player = () => {
  return (
    <Wrapper>
      <div id="player-root" />
    </Wrapper>
  );
};

export default Player;
