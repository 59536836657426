import React from 'react';
import { Page, Text } from '@react-pdf/renderer';
import {
  ImgWrapper,
  HeadingWrapper as Heading,
  GraphicWrapper,
} from './sectionTitle.styles';

import GraphicImg from '../../assets/Big_green_sapphire1.png';

const SectionTitlePage = ({ number, title, imgUrl }) => (
  <Page size="A4" orientation="landscape">
    <ImgWrapper src={imgUrl} />
    <GraphicWrapper src={GraphicImg} />
    <Heading>
      <Text style={{ fontSize: '94pt' }}>{number}</Text>
      <Text style={{ fontSize: '31pt' }}>{title}</Text>
    </Heading>
  </Page>
);

export default SectionTitlePage;
