import styled from '@react-pdf/styled-components';
import { COLORS } from '../../constants';

export const BackgroundWrapper = styled.View`
  height: 100%;
  width: 100%;
  /* background: ${COLORS.green}; */
  background: #1C4677;
`;

export const ImageWrapper = styled.Image`
  height: 25px;
  width: 150px;
  object-fit: contain;
`;

export const UpperWrapper = styled.View`
  position: absolute;
  top: 5vh;
  left: 5vw;
`;

export const LowerWrapper = styled.View`
  width: 300px;
  position: absolute;
  top: 90vh;
  left: 5vw;

  color: white;
  font-size: 8px;

  display: flex;
  flex-direction: row;
`;
