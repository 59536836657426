import styled from 'styled-components';
import { MEDIA_QUERY } from '../../constants';

const width = {
  tabletP: '320px',
  tabletL: '720px',
};

export const Wrapper = styled.div`
  padding-top: 20px;
  background: #f3f2f3;
  overflow: scroll;

  @media ${MEDIA_QUERY.mobile} {
    height: ${window.innerHeight}px;
    padding: 8px 12px;

    h1 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 600;
      color: ${props => props.theme.mobile.secondary.fontDefaultColor};
    }
  }

  @media ${MEDIA_QUERY.tabletP} {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 175px;
    width: calc(100vw - 175px);
    padding-top: 20px;
    padding-bottom: 40px;
    z-index: 10;

    & > div {
      width: min-content;
      position: relative;
      left: 50%;
      transform: translate(-50%);

      @media ${MEDIA_QUERY.tabletP} {
        width: ${width.tabletP};
      }

      @media ${MEDIA_QUERY.tabletL} {
        width: ${width.tabletL};
      }
    }
    h1 {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

export const Block = styled.div`
  @media ${MEDIA_QUERY.mobile} {
    margin-bottom: 18px;

    h2 {
      margin-bottom: 7px;
      font-size: 16px;
      font-weight: 600;
      color: ${props => props.theme.mobile.secondary.fontDefaultColor};
    }
  }

  @media ${MEDIA_QUERY.tabletP} {
    background: white;
    padding: 18px;
    margin-bottom: 15px;
    max-width: 320px;

    h2 {
      color: ${props => props.theme.colors.primary};
    }
  }

  @media ${MEDIA_QUERY.tabletL} {
    background: white;
    padding: 22px;
    margin-bottom: 15px;
    max-width: 720px;

    h2 {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

export const BlockContent = styled.div`
  display: grid;

  @media ${MEDIA_QUERY.mobile} {
    grid-template-columns: 115px max-content;
    line-height: 1.8em;
  }

  @media ${MEDIA_QUERY.tabletP} {
    grid-template-columns: 150px max-content;
  }

  @media ${MEDIA_QUERY.tabletL} {
    grid-template-columns: 200px max-content;
  }

  .indent {
    @media ${MEDIA_QUERY.mobile} {
      padding-left: 10px;
    }

    @media ${MEDIA_QUERY.tabletP} {
      padding-left: 15px;
    }
  }
`;

export const ActionButtons = styled.div`
  @media ${MEDIA_QUERY.mobile} {
    & > div {
      margin-top: 15px;
      text-align: center;
    }
  }

  @media ${MEDIA_QUERY.tabletP} {
    display: flex;
    flex-direction: row;
  }
`;
