import styled from 'styled-components';
import { MEDIA_QUERY } from '../../../constants';

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: 10px;

  @media ${MEDIA_QUERY.mobile} {
    & > div:nth-child(2) {
      display: none;
    }
  }
`;

export const Title = styled.div`
  @media ${MEDIA_QUERY.mobile} {
    font-size: 14px;
    margin-bottom: 8px;
  }

  @media ${MEDIA_QUERY.tabletP} {
    font-size: 18px;
    margin-bottom: 12px;
  }
`;

export const Description = styled.div`
  margin-bottom: 8px;
`;
