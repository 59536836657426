import React, { useState, useContext, Fragment } from 'react';
import { Wrapper, Block, BlockContent, ActionButtons } from './summary.styles';
import { Button } from 'antd';
import { isUuid } from 'uuidv4';
import { AppContext } from '../../context';

import FormModal from '../FormModal';

export default () => {
  const [sku, setSku] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user, address, configuration, pdfUrl, generateSku } = useContext(
    AppContext
  );

  const handleClickPDF = () => {
    if (!pdfUrl) return;
    window.open(pdfUrl);
  };

  const handleSave = () => {
    setShowModal('download');
  };

  const skuStyle = {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    width: '400px',
  };
  if (address && address[0] === 4)
    return (
      <>
        <Wrapper className="scrollable">
          <div>
            <h1>Specifications</h1>
            <Block>
              <h2>Connection</h2>
              <BlockContent>
                <div>Slab:</div>
                <div>{configuration['Anchor']}</div>
                <div>Slab thickness:</div>
                <div>
                  {configuration['Anchor'] === 'Cast In'
                    ? configuration['Cast In Slab Thickness']
                    : configuration['Post Fixed Slab Thickness']}
                </div>
                <div>Floor thickness:</div>
                <div>
                  {configuration['Anchor'] === 'Cast In'
                    ? configuration['Cast In Floor Thickness']
                    : configuration['Post Fixed Floor Thickness']}
                </div>
              </BlockContent>
            </Block>
            <Block>
              <h2>Cassette</h2>
              <BlockContent>
                <div>Shape</div>
                <div>{configuration['Shape']}</div>
                <div className="indent">Left angle:</div>
                <div>{configuration['Left Angle']}</div>
                <div className="indent">Right angle:</div>
                <div>{configuration['Right Angle']}</div>
                <div>Size</div>
                <div></div>
                <div className="indent">Projection:</div>
                <div>{configuration['Projection']}</div>
                <div className="indent">Width:</div>
                <div>{configuration['Width']}</div>
                <div className="indent">Depth:</div>
                <div>{configuration['Depth']}</div>
                <div>Drainage:</div>
                <div>{configuration['Drainage']}</div>
                <div>Decking:</div>
                <div>{configuration['Fire Class']}</div>
              </BlockContent>
            </Block>
            <Block>
              <h2>Balustrade</h2>
              <BlockContent>
                {['Front', 'Left', 'Right'].map((position) => (
                  <Fragment key={position}>
                    <div>{position}</div>
                    <div></div>
                    <div className="indent">Type:</div>
                    <div>{configuration[`${position} Type`]}</div>
                    {configuration[`${position} Type`] ===
                      'Structural Glass' && (
                      <Fragment>
                        <div className="indent">Glass Type:</div>
                        <div>{configuration[`${position} Glass Type`]}</div>
                      </Fragment>
                    )}
                    {configuration[`${position} Type`] === 'Solid Wall' && (
                      <Fragment>
                        <div className="indent">Thickness:</div>
                        <div>{configuration[`${position} Wall Thickness`]}</div>
                      </Fragment>
                    )}
                    {configuration[`${position} Type`] !== 'No Balustrade' && (
                      <Fragment>
                        <div className="indent">Height:</div>
                        <div>{configuration[`${position} Height`]}</div>
                      </Fragment>
                    )}
                    {(configuration[`${position} Type`] ===
                      'Structural Glass' ||
                      configuration[`${position} Type`] ===
                        'Vertical Bars') && (
                      <Fragment>
                        <div className="indent">Top rail:</div>
                        <div>{configuration[`${position} Toprail`]}</div>
                      </Fragment>
                    )}
                    {configuration[`${position} Type`] ===
                      'Structural Glass' && (
                      <Fragment>
                        <div className="indent">Fascia:</div>
                        <div>{configuration[`${position} Glass Fascia`]}</div>
                      </Fragment>
                    )}
                    {configuration[`${position} Type`] === 'Vertical Bars' && (
                      <Fragment>
                        <div className="indent">Fascia:</div>
                        <div>
                          {configuration[`${position} Vertical Bars Fascia`]}
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                ))}
              </BlockContent>
            </Block>
            <Block>
              <h2>Colour</h2>
              <BlockContent>
                <div>Deck:</div>
                <div>
                  {typeof configuration['Decking Colour'] === 'string'
                    ? isUuid(configuration['Decking Colour'])
                      ? window.player.sceneGraph.nodes[
                          configuration['Decking Colour']
                        ].name
                      : configuration['Decking Colour']
                    : configuration['Decking Colour'].assetId.length
                    ? window.player.sceneGraph.nodes[
                        configuration['Decking Colour'].assetId
                      ].name
                    : null}
                </div>
                <div>Facade:</div>
                <div>
                  {typeof configuration['Facade Colour'] === 'string'
                    ? isUuid(configuration['Facade Colour'])
                      ? window.player.sceneGraph.nodes[
                          configuration['Facade Colour']
                        ].name
                      : configuration['Facade Colour']
                    : configuration['Facade Colour'].assetId.length
                    ? window.player.sceneGraph.nodes[
                        configuration['Facade Colour'].assetId
                      ].name
                    : null}
                </div>
                <div>Balcony:</div>
                <div>
                  {typeof configuration['Balcony Colour'] === 'string'
                    ? isUuid(configuration['Balcony Colour'])
                      ? window.player.sceneGraph.nodes[
                          configuration['Balcony Colour']
                        ].name
                      : configuration['Balcony Colour']
                    : configuration['Balcony Colour'].assetId.length
                    ? window.player.sceneGraph.nodes[
                        configuration['Balcony Colour'].assetId
                      ].name
                    : null}
                </div>
              </BlockContent>
            </Block>
            {process.env.REACT_APP_ENV !== 'production' && (
              <Block>
                <h2>SKU</h2>
                <BlockContent>
                  <div>
                    <Button
                      onClick={() => {
                        setSku(generateSku(configuration));
                      }}
                    >
                      Generate SKU
                    </Button>
                  </div>
                  <div style={skuStyle}>{sku}</div>
                </BlockContent>
              </Block>
            )}
            <ActionButtons>
              {/*<div>
                <Button
                  onClick={() => setShowModal('save')}
                  style={{ marginRight: '15px' }}
                >
                  Save Balcony Type
                </Button>
              </div>*/}
              <div>
                {pdfUrl ? (
                  <Button
                    type="primary"
                    style={{ marginRight: '15px' }}
                    onClick={handleClickPDF}
                  >
                    View PDF
                  </Button>
                ) : (
                  <Button
                    onClick={handleSave}
                    loading={loading}
                    style={{ marginRight: '15px' }}
                  >
                    {loading ? 'Prepping PDF' : 'Save & download'}
                  </Button>
                )}
              </div>
              {/* <div>
                <Button style={{ marginRight: '15px' }}>Share</Button>
              </div> */}
            </ActionButtons>
          </div>
        </Wrapper>
        {showModal && (
          <FormModal
            allowDownload={showModal === 'download'}
            handleClose={() => setShowModal(null)}
            setLoading={setLoading}
            loading={loading}
          />
        )}
      </>
    );

  return null;
};
