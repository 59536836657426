import styled from '@react-pdf/styled-components';

export const Left = styled.View`
  width: 300px;
  margin-right: 15px;
`;

export const Right = styled.View`
  width: 320px;
`;

export const Heading = styled.Text`
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 15px;
`;
