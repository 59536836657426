import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { Wrapper, Item, ItemLeft, AltP, P } from './page51.styles';

import PageTemplate from '../../Templates/PageTemplate';

const balconyColors = {
  'Light Grey': 'light grey to RAL (TBC)',
  'Dark Grey': 'dark grey to RAL (TBC)',
  'Dark Bronze': 'dark bronze to RAL (TBC)',
  'Mid Bronze': 'mid bronze to RAL (TBC)',
  Stone: 'Stone effect to RAL (TBC)',
  White: 'white to RAL (TBC)',
};

const prepResult = (optionsObj, valuesArr, prefix = '', suffix = '.') => {
  const result = valuesArr.reduce((output, opt, i) => {
    if (i === 0) output = output + optionsObj[opt];
    else if (i < valuesArr.length - 1) output = output + `, ${optionsObj[opt]}`;
    else output = output + ` and ${optionsObj[opt]}`;
    return output;
  }, prefix);

  return result + '.';
};

const getReference = (balustradesArray) => {
  const prefix = 'Glide-On™ aluminium Cassette® balconies, with ';
  const options = {
    'Structural Glass': 'Crystal® frameless structural glass',
    'Vertical Bars': 'vertical bar balustrades',
    'Solid Wall': 'solid end panels',
  };

  return prepResult(options, balustradesArray, prefix);
};

const getSoffits = (hasRoundToprail, drainageArray, color) => {
  const options = {
    'Controlled Drainage':
      'individually controlled draining to front of balcony',
    'Positive Drainage': 'positively draining to gutter/RWP at rear of balcony',
  };
  const prefix1 = 'Polyester powder coated aluminium soffits ';
  const prefix2 = ', with 400mm wide drip trays ';
  const prefixOptional = balconyColors[color];
  const prefix = prefix1 + (hasRoundToprail ? '' : prefixOptional) + prefix2;

  return prepResult(options, drainageArray, prefix);
};

const getToprail = (hasRoundToprail, toprailArray, color) => {
  const options = {
    'Rectangular Handrail':
      '60x30mm Rectangular Polyester powder coated aluminium handrail ',
    Capping: '27x27mm square Polyester powder coated aluminium capping',
    'Round Handrail':
      '50mm diameter slotted 316 grade stainless steel handrail',
  };
  const suffix = (!hasRoundToprail && ` ${balconyColors[color]}`) + '.';

  return prepResult(options, toprailArray, '', suffix);
};


const getGuarding = (side, value, color) => {
  const options = {
    'Structural Glass':
      '21.5mm frameless structural glass formed of 2 panels toughened 10mm glass laminated together with 1.5mm PVB interlayer',
    'Vertical Bars': 'Polyester powder coated aluminium vertical bars',
    'Solid Wall': 'Polyester powder coated aluminium solid end box',
    'No Balustrade': 'No Balustrade'
  };
  let suffix = (value === 'Vertical Bars' || value === 'Solid Wall') ? `, ${balconyColors[color]}.` : '.'

return <><Text style={{fontFamily: 'MyriadProBold'}}>{side}: </Text> {options[value] + suffix}</>
};

const getFascia = (side, balustrade, barsOpt, glassOpt, color) => {
  const prefixOptions = {
    'vertical-bars': 'Polyester powder coated aluminium fascias',
    'printed-band':
      'Screen printed band printed on glass with matching point fixing discs',
  };
  const options = {
    'In Front': ' Fascias to be fixed in front of bars.',
    Behind: ' Fascias to be fixed behind bars.',
  };

  let prefix = ''
  let suffix = ''
  if (
    balustrade === 'Vertical Bars' ||
    (balustrade === 'Structural Glass' &&
      glassOpt === 'Fascia Trim')
  )
    prefix = prefixOptions['vertical-bars'];
  if (
    balustrade === 'Structural Glass' &&
    glassOpt === 'Printed Band'
  )
  prefix = prefixOptions['printed-band'];

  if(!prefix.length) return <><Text style={{fontFamily: 'MyriadProBold'}}>{side}: </Text> No fascia.</>

  if(balustrade === 'Vertical Bars') suffix = options[barsOpt]

  return <><Text style={{fontFamily: 'MyriadProBold'}}>{side}: </Text> {prefix}, {balconyColors[color]}.{suffix}</>
};

const deckingFinishing = {
  Anthracite: 'S1 - Anthracite',
  'Window Grey': 'S2 - Window Grey',
  'Quartz Grey': 'S3 - Quartz Grey',
  'Dusty Grey': 'S4 – Dusty Grey',
  'Sepia Brown': 'S5 – Sepia Brown',
  'Grey Brown': 'S6 – Grey Brown',
};

export default ({
  hasRoundToprail,
  balconyColor,
  balustradeOpts,
  balustradeLeft,
  balustradeRight,
  balustradeFront,
  frontGlassFascia,
  leftGlassFascia,
  rightGlassFascia,
  frontBarsFascia,
  leftBarsFascia,
  rightBarsFascia,
  drainageOpt,
  deckingColor,
  // frontRailing,
  toprailOptions,
  fasciaOptions,
  fasciaPosition,
  fireClass,
}) => {
  return (
    <PageTemplate number="5.1" title="NBS specification">
      <Wrapper>
        <Item>
          <ItemLeft>
            <P>Manufacturer:</P>
          </ItemLeft>
          <View>
            <AltP>Sapphire Balconies Ltd</AltP>
            <AltP>11 Arkwright Road</AltP>
            <AltP>Reading</AltP>
            <AltP>RG2 0LU</AltP>
            <AltP>0344 88 00 553</AltP>
            <AltP>sales@sapphire.eu.com</AltP>
            <AltP>www.sapphire.eu.com</AltP>
          </View>
        </Item>
        <Item>
          <ItemLeft>
            <P>Reference:</P>
          </ItemLeft>
          <View>
            <P>{getReference(balustradeOpts)}</P>
          </View>
        </Item>
        <Item>
          <ItemLeft>
            <P>Balcony anchor:</P>
          </ItemLeft>
          <View>
            <P>
              Sapphire cast-in anchor, preassembled with thermal breaks and
              concrete edge casting shutter.
            </P>
          </View>
        </Item>
        <Item>
          <ItemLeft>
            <P>Arms:</P>
          </ItemLeft>
          <View>
            <P>Two-piece galvanised steel arms.</P>
          </View>
        </Item>
        <Item>
          <ItemLeft>
            <P>Cassette® structure:</P>
          </ItemLeft>
          <View>
            <P>
              Standard 400mm modular Glide-On™ Aluminium Cassette® balconies
              with levelling and clamping devices preassembled.
            </P>
          </View>
        </Item>
        <Item>
          <ItemLeft>
            <P>Soffits:</P>
          </ItemLeft>
          <View>
            <P>{getSoffits(hasRoundToprail, [drainageOpt], balconyColor)}</P>
          </View>
        </Item>
        <Item>
          <ItemLeft>
            <P>Deck finish:</P>
          </ItemLeft>
          <View>
            <P>
              {fireClass === 'Class A' ? 'Aluminum ' : 'WPC '}composite decking (Fire {fireClass}) 
              fixed with hidden clips. Decking colour to be {deckingFinishing[deckingColor]}, with grooved finish.
            </P>
          </View>
        </Item>
        <Item>
          <ItemLeft>
            <P>Toprail:</P>
          </ItemLeft>
          <View>
            <P>{getToprail(hasRoundToprail, toprailOptions, balconyColor)}</P>
          </View>
        </Item>
        <Item>
          <ItemLeft>
            <P>Guarding:</P>
          </ItemLeft>
          <View>
            <P>
              {getGuarding('Front', balustradeFront, balconyColor)}
            </P>
            <P>
              {getGuarding('Left', balustradeLeft, balconyColor)}
            </P>
            <P>
              {getGuarding('Right', balustradeRight, balconyColor)}
            </P>
          </View>
        </Item>
        <Item>
          <ItemLeft>
            <P>Base fixing:</P>
          </ItemLeft>
          <View>
            <P>Mechanically fixed to Cassette®.</P>
          </View>
        </Item>
        <Item>
          <ItemLeft>
            <P>Fascia:</P>
          </ItemLeft>
          <View>
            {/* <P>{getFascia(fasciaType, fasciaPosition, balconyColor)}</P> */}
            <P>{getFascia('Front', balustradeFront, frontBarsFascia, frontGlassFascia, balconyColor)}</P>
            <P>{getFascia('Left', balustradeLeft, leftBarsFascia, leftGlassFascia, balconyColor)}</P>
            <P>{getFascia('Right', balustradeRight, rightBarsFascia, rightGlassFascia, balconyColor)}</P>
          </View>
        </Item>
      </Wrapper>
    </PageTemplate>
  );
};
