import React from 'react';
import { Image, View, Text } from '@react-pdf/renderer';
import {
  H2,
  P,
  Widget,
  WidgetRow,
  WidgetHeading,
  WidgetItem,
  Chip,
} from '../../shared.styles';
import { Left, Right, TableHeading, TableRow } from './page31.styles';
import data from './data.json';

import PageTemplate from '../../Templates/PageTemplate';

import Image1 from '../../assets/page31_01.png';

export default ({ width, projection }) => {
  return (
    <PageTemplate number="3.1" title="Rigidity considerations">
      <Left>
        <Image src={Image1} />
      </Left>
      <Right>
        {data.sections.map((section) => (
          <>
            <H2>{section.title}</H2>
            {section.paragraphs.map((p) => (
              <P>{p}</P>
            ))}
          </>
        ))}
        <View style={{ marginBottom: '15px', marginTop: '15px' }}>
          <TableHeading>
            {data.table.columns.map((el, i) => {
              let width = i === 0 ? '90px' : i === 1 ? '120px' : null;
              return <Text style={{ width }}>{el}</Text>;
            })}
          </TableHeading>
          {data.table.data.map((el, i) => {
            let background = i % 2 === 0 ? 'light' : 'dark';
            return (
              <TableRow background={background}>
                {el.map((item, j) => {
                  let width = j === 0 ? '90px' : j === 1 ? '120px' : null;

                  return <Text style={{ width }}>{item}</Text>;
                })}
              </TableRow>
            );
          })}
        </View>
        <Widget>
          <WidgetHeading>Your balcony design</WidgetHeading>
          <WidgetRow>
            <WidgetItem style={{ width: '90px' }}>Width</WidgetItem>
            <WidgetItem>{width / 1000} metres</WidgetItem>
          </WidgetRow>
          <WidgetRow>
            <WidgetItem style={{ width: '90px' }}>Projection</WidgetItem>
            <WidgetItem>{projection / 1000} metres</WidgetItem>
          </WidgetRow>
          <Chip />
        </Widget>
      </Right>
    </PageTemplate>
  );
};
