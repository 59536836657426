import React, { useContext } from 'react';
import { Wrapper } from './viewSwitcher.styles';
import { AppContext } from '../../context';

import thumbnailExterior from '../../assets/thumbnail-exterior.jpeg';
import thumbnailInterior from '../../assets/thumbnail-interior.jpeg';
import thumbnailAnchor from '../../assets/thumbnail-anchor.jpg';

const ViewSwitcher = () => {
  const { setCamera, configuration } = useContext(AppContext);
  const { View: view } = configuration || { View: null };

  const handleClickView = () => {
    if (view === 'Interior') setCamera('Exterior');
    else setCamera('Interior');
  };

  const handleClickAnchor = () => {
    if (view === 'Anchor') setCamera('Exterior');
    else setCamera('Anchor');
  };

  if (!view) return null;

  return (
    <Wrapper>
      <div onClick={handleClickView}>
        <img
          src={view === 'Interior' ? thumbnailExterior : thumbnailInterior}
          alt="interior-view"
        />
        <div>{view === 'Interior' ? 'Exterior View' : 'Interior View'}</div>
      </div>

      <div onClick={handleClickAnchor}>
        <img src={thumbnailAnchor} alt="anchor-view" />
        <div>
          {view === 'Anchor'
            ? 'Hide Construction Details'
            : 'Show Construction Details'}
        </div>
      </div>
    </Wrapper>
  );
};

export default ViewSwitcher;
