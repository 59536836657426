import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { Wrapper } from './desktopConfigurator.styles';
import PrimarySecondarySection from './PrimarySecondarySection';
import TertiarySection from './TertiarySection';
import Summary from '../../Summary';

const DesktopConfigurator = () => {
  const state = useContext(AppContext);
  const { data, address, handleSetSection } = state || {
    data: null,
    address: [],
  };
  const [expand, setExpand] = useState(false);

  const handleClickPrimary = idx => {
    if (idx === state.address[0]) return setExpand(!expand);
    handleSetSection([idx, 0]);
    setExpand(true);
  };

  const handleClickSecondary = address => {
    handleSetSection(address);
    setExpand(true);
  };
  return (
    <>
      <Wrapper>
        {data && (
          <>
            <PrimarySecondarySection
              configurator={data}
              selectedPrimary={address[0]}
              selectedSecondary={address[1]}
              expand={expand}
              handleClickPrimary={handleClickPrimary}
              handleClickSecondary={handleClickSecondary}
            />
            {state.address &&
              data[address[0]] &&
              data[address[0]].sections[address[1]] && (
                <TertiarySection
                  address={[address[0], address[1]]}
                  {...data[address[0]].sections[address[1]]}
                />
              )}
          </>
        )}
      </Wrapper>
      <Summary />
    </>
  );
};

export default DesktopConfigurator;
