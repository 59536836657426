import styled from 'styled-components';
import { MEDIA_QUERY } from '../../constants';
import { MOBILE } from '../Configurator/MobileConfigurator/mobileConfigurator.styles';

const size = {
  desktop: {
    height: '100px',
    width: '132px',
  },
  mobile: {
    height: MOBILE.buttonSize,
    width: MOBILE.buttonSize,
  },
};

export const Wrapper = styled.div`
  @media ${MEDIA_QUERY.mobile} {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 15px;
    position: fixed;
    top: 90%;
    left: calc(10% + ${size.mobile.height} + 15px);

    & > div {
      height: ${size.mobile.height};
      width: ${size.mobile.width};
      border: 1px solid white;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s;
      opacity: 0.8;

      &:hover {
        box-shadow: 2px 0px 8px #00002444;
      }

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }

      & > div {
        display: none;
      }
    }
  }

  @media ${MEDIA_QUERY.tabletP} {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 15px;
    position: fixed;
    bottom: 50px;
    left: 525px;

    & > div {
      height: ${size.desktop.height};
      width: ${size.desktop.width};
      border: 1px solid white;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        box-shadow: 2px 0px 8px #00002444;
      }

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
        border-radius: 4px;
      }

      & > div {
        width: 100%;
        text-align: center;
        background: rgba(255, 255, 255, 0.9);
        transform: translateY(-100%);

        font-size: 14px;
      }
    }

    & > div:nth-child(2) img {
      object-fit: cover;
    }
  }
`;
