import React from 'react';
import { Slider, InputNumber } from 'antd';
import { ContentWrapper, Title, Description } from './slider.styles';

const SliderInput = ({
  label,
  description,
  value,
  postscript,
  min,
  max,
  step,
  onChange,
  configuration,
}) => {
  const handleChangeSlider = (value) => {
    Array.isArray(value) ? onChange(value[0]) : onChange(value);
  };
  const validateValue = (value) =>
    value >= min && value <= max && (value - min) % step === 0;
  const handleChangeInput = (value) => {
    value && validateValue(value) && onChange(value);
  };
  return (
    <div>
      <Title>{label}</Title>
      {description && (
        <Description
          dangerouslySetInnerHTML={{ __html: description }}
        ></Description>
      )}
      <ContentWrapper>
        <Slider
          value={configuration}
          min={min}
          max={max}
          step={step}
          onChange={handleChangeSlider}
        />
        <InputNumber
          min={min}
          max={max}
          step={step}
          value={configuration}
          onChange={handleChangeInput}
          style={{ width: 70 }}
        />
      </ContentWrapper>
      {postscript && (
        <div
          style={{ marginTop: '8px' }}
          dangerouslySetInnerHTML={{ __html: postscript }}
        ></div>
      )}
    </div>
  );
};

export default SliderInput;
