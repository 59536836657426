import React from 'react';
import { Page, Text, Image } from '@react-pdf/renderer';
import {
  BackgroundWrapper,
  BackgroundImgWrapper,
  FooterWrapper,
  FooterImgWrapper,
  ContentWrapper,
  Heading,
  TwoCol,
  Col,
  Item,
} from './tableOfContents.styles';
import BackgroundImg from '../../assets/Big_green_sapphire1.png';
import FooterLeftImg from '../../assets/footerLeft.png';
import FooterRightImg from '../../assets/footerRight.png';

const Template = ({ children }) => {
  return (
    <Page size="A4" orientation="landscape">
      <BackgroundWrapper>
        <BackgroundImgWrapper>
          <Image src={BackgroundImg} />
        </BackgroundImgWrapper>
        <FooterWrapper>
          <FooterImgWrapper>
            <Image src={FooterLeftImg} />
          </FooterImgWrapper>
          <FooterImgWrapper>
            <Image src={FooterRightImg} />
          </FooterImgWrapper>
        </FooterWrapper>
      </BackgroundWrapper>
      {children}
    </Page>
  );
};

const TableOfContents = () => {
  return (
    <Template>
      <ContentWrapper>
        <Heading>CONTENTS</Heading>
        <TwoCol>
          <Col>
            <Item>
              <Text style={{ width: '25px' }}>1.0</Text>
              <Text>Balcony specification overview</Text>
            </Item>
            <Item>
              <Text style={{ width: '25px' }}>2.0</Text>
              <Text>Material choice</Text>
            </Item>
            <Item>
              <Text style={{ width: '25px' }}>3.0</Text>
              <Text>Rigidity considerations</Text>
            </Item>
            <Item>
              <Text style={{ width: '25px' }}>4.0</Text>
              <Text>Structural load summary</Text>
            </Item>
            <Item>
              <Text style={{ width: '25px' }}>5.0</Text>
              <Text>NBS specification</Text>
            </Item>
          </Col>
          <Col>
            <Item>
              <Text style={{ width: '25px' }}>6.0</Text>
              <Text>Child safety</Text>
            </Item>
            <Item>
              <Text style={{ width: '25px' }}>7.0</Text>
              <Text>Fire considerations</Text>
            </Item>
            <Item>
              <Text style={{ width: '25px' }}>8.0</Text>
              <Text>Drainage considerations</Text>
            </Item>
            <Item>
              <Text style={{ width: '25px' }}>9.0</Text>
              <Text>End user considerations</Text>
            </Item>
            <Item>
              <Text style={{ width: '25px' }}>10.0</Text>
              <Text>Precedent project images and case studies</Text>
            </Item>
          </Col>
        </TwoCol>
      </ContentWrapper>
    </Template>
  );
};

export default TableOfContents;
