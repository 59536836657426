export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'http://localhost:3000';
export const SERVER_URL =
  process.env.REACT_APP_BASE_URL || 'http://localhost:5000';
export const AUTH_TOKEN =
  process.env.REACT_APP_AUTH_TOKEN || 'd7ff48c4-a627-4c51-a232-f37196edc891'; //  the default token is for localhost:3000
export const ASSET_ID =
  process.env.REACT_APP_ASSET_ID || '1ce8f446-c758-4e71-a470-65830ed13370';
// export const STAGE_ID = 'ed87c6f9-6e32-4d99-877a-17a68d2ba9a6';
export const STAGE_ID = '8341b53c-5112-4730-80f4-d7e47c5dd55a';
export const CAMERAS = {
  Exterior: 'Exterior_Camera',
  Interior: 'Interior_Camera',
  Anchor: 'Anchor_Camera',
  snapshot: {
    Exterior: 'Exterior_Screenshot_Camera',
    Interior: 'Interior_Screenshot_Camera',
    Topview: 'Topview_Screenshot_Camera',
    Bottomview: 'Bottomview_Screenshot_Camera',
  },
};

export const deviceSizes = {
  mobile: '599px',
  tabletP: '600px',
  tabletL: '900px',
  desktop: '1200px',
};

export const MEDIA_QUERY = {
  mobile: `(max-width: ${deviceSizes.mobile})`,
  tabletP: `(min-width: ${deviceSizes.tabletP})`,
  tabletL: `(min-width: ${deviceSizes.tabletL})`,
  desktop: `(min-width: ${deviceSizes.tabletL})`,
};
