import React from 'react';
import image from '../../assets/page71.jpg';
import {
  H2,
  P,
  Widget,
  WidgetRow,
  WidgetHeading,
  WidgetItem,
  ListItem,
} from '../../shared.styles';
import { Left, Right } from './page71.styles';
import { View, Image } from '@react-pdf/renderer';

import data from './data.json';

import PageTemplate from '../../Templates/PageTemplate';

export default ({ fireClass }) => {
  return (
    <PageTemplate number="7.1" title="Fire considerations">
      <Left>
        <P>{data.intro}</P>
        <View>
          <Image src={image} />
        </View>
      </Left>
      <Right>
        {data.sections.map((section, i) => (
          <>
            <H2 style={{ marginTop: i > 0 ? '10px' : '0px' }}>
              {section.title}
            </H2>
            {section.paragraphs.map(p => (
              <P>{p}</P>
            ))}
            {section.list.map(el => (
              <ListItem>{el}</ListItem>
            ))}
          </>
        ))}
        <Widget style={{ marginTop: '10px' }}>
          <WidgetHeading>Your balcony design</WidgetHeading>
          <WidgetRow>
            <WidgetItem style={{ width: '150px' }}>Stub/Arm</WidgetItem>
            <WidgetItem>Class A</WidgetItem>
          </WidgetRow>
          <WidgetRow>
            <WidgetItem style={{ width: '150px' }}>
              Balcony structure
            </WidgetItem>
            <WidgetItem>Class A</WidgetItem>
          </WidgetRow>
          <WidgetRow>
            <WidgetItem style={{ width: '150px' }}>Deck finish</WidgetItem>
            <WidgetItem>{fireClass}</WidgetItem>
          </WidgetRow>
          <WidgetRow>
            <WidgetItem style={{ width: '150px' }}>Soffit</WidgetItem>
            <WidgetItem>Class A</WidgetItem>
          </WidgetRow>
          <WidgetRow>
            <WidgetItem style={{ width: '150px' }}>Fascia</WidgetItem>
            <WidgetItem>Class A</WidgetItem>
          </WidgetRow>
          <WidgetRow>
            <WidgetItem style={{ width: '150px' }}>Balustrade</WidgetItem>
            <WidgetItem>Class A</WidgetItem>
          </WidgetRow>
          <WidgetRow>
            <WidgetItem style={{ width: '150px' }}>Toprail</WidgetItem>
            <WidgetItem>Class A</WidgetItem>
          </WidgetRow>
        </Widget>
      </Right>
    </PageTemplate>
  );
};
