import styled from 'styled-components';

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 16;

  padding: 30px 42px;
  background: white;
  border-radius: 3px;

  & > div {
    margin-top: 30px;
    text-align: right;
  }

  h4 {
    margin-top: 12px;
  }
`;
