import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { Toggle } from './mobileConfigurator.styles';
import PrimarySection from './PrimarySection';
import SecondarySection from './SecondarySection';
import TertiarySection from './TertiarySection';
import { Icon } from 'antd';

const MobileConfigurator = ({ show }) => {
  const state = useContext(AppContext);
  const { data, address, handleSetSection } = state || {
    data: null,
    address: [],
  };
  const [showElement, setShowElement] = useState(true);
  const [showConfigurator, setShowConfigurator] = useState(null);

  show !== showElement && show
    ? setShowElement(show)
    : setTimeout(() => setShowElement(show), 0.4 * 1000);

  const handleClickToggle = () => {
    if (showConfigurator) {
      setShowConfigurator(null);
    } else setShowConfigurator('primary');
  };

  const handleSelectPrimary = idx => {
    handleSetSection([idx, 0]);
    setShowConfigurator('secondary');
  };

  const handleSelectSecondary = idx => {
    handleSetSection([address[0], idx]);
    setShowConfigurator('tertiary');
  };

  return (
    <>
      {!!data && (
        <>
          <Toggle
            onClick={handleClickToggle}
            expand={!!showConfigurator}
            visible={!!showElement}
            show={!!showConfigurator}
          >
            <div>
              <div>
                <Icon style={{ fontSize: '20px' }} type="appstore" />
              </div>
              <div>
                <Icon type="close" />
              </div>
            </div>
            <div />
          </Toggle>
          <PrimarySection
            show={
              address[0] === 4 && showConfigurator === 'secondary'
                ? 'tertiary'
                : showConfigurator
            }
            selected={address[0]}
            onClickBackground={() => setShowConfigurator('primary')}
            onSelect={handleSelectPrimary}
            configurator={data}
          />
          <SecondarySection
            show={
              address[0] === 4 && showConfigurator === 'secondary'
                ? 'tertiary'
                : showConfigurator
            }
            address={address}
            selected={address[1]}
            onClickBackground={() => setShowConfigurator('secondary')}
            onSelect={handleSelectSecondary}
            configurator={address[0] != null ? data[address[0]].sections : null}
          />
          <TertiarySection
            show={showConfigurator}
            address={address}
            configurator={
              address[0] != null &&
              address[1] != null &&
              !!data[address[0]].sections.length
                ? data[address[0]].sections[address[1]]
                : null
            }
          />
        </>
      )}
    </>
  );
};

export default MobileConfigurator;
