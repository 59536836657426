import { message } from 'antd';

message.config({
  maxCount: 1,
});

const validateSize = (updatedVals, configuration) => {
  let angleL, angleR, width, height;
  angleL = updatedVals['Left Angle'] || configuration['Left Angle'];
  angleR = updatedVals['Right Angle'] || configuration['Right Angle'];
  //   If the balcony is as wide or wider than the base
  //   then we can return
  if (angleL + angleR >= 180) return true;

  width = updatedVals['Width'] || configuration['Width'];
  height = updatedVals['Projection'] || configuration['Projection'];

  let deltaL = height / Math.tan((angleL * Math.PI) / 180);
  let deltaR = height / Math.tan((angleR * Math.PI) / 180);
  console.log('width', width);
  console.log('deltaL', deltaL);
  console.log('deltaR', deltaR);
  console.log('calculated', width - (deltaL + deltaR));
  if (width - (deltaL + deltaR) < 400) {
    let text;
    if ('Left Angle' in updatedVals || 'Right Angle' in updatedVals)
      text = 'Angle too small for existing configuration';
    if ('Width' in updatedVals)
      text = 'Width too narrow for existing configuration';
    if ('Projection' in updatedVals)
      text = 'Projection too long for existing configuration';

    message.error(text);
    return false;
  }

  return true;
};

export default {
  validateSize,
};
