import React from 'react';
import { View } from '@react-pdf/renderer';
import {
  H2,
  P,
  Widget,
  WidgetRow,
  WidgetItem,
  WidgetHeading,
} from '../../shared.styles';
import {
  TwoCol,
  Left,
  Right,
  ImageCols,
  ImgWrapper,
  ImgWrapperAlt,
} from './page92.styles';

import PageTemplate from '../../Templates/PageTemplate';

import Image1 from '../../assets/page92_01.jpg';
import Image2 from '../../assets/page92_02.jpg';
import Image3 from '../../assets/page92_03.jpg';

export default ({
  leftThickness,
  rightThickness,
  leftGlass,
  rightGlass,
  frontGlass,
  hasWall,
  hasGlass,
}) => {
  return (
    <>
      {hasWall && (
        <PageTemplate number="9.2" title="End user considerations">
          <View>
            <TwoCol>
              <Left>
                <H2>Balcony privacy: solid walls</H2>
                <P>
                  Solid walls can be used as privacy screens as well as part of
                  a building’s architectural style. These ‘end boxes’, as they
                  are often called, have a structural framework with a coated
                  aluminium cladding. The thickness of the box can vary
                  according to the project requirements.
                </P>
              </Left>
              <Right>
                <Widget>
                  <WidgetHeading>Your balcony design</WidgetHeading>
                  <WidgetRow>
                    <WidgetItem style={{ width: '90px', marginRight: '15px' }}>
                      Left
                    </WidgetItem>
                    <WidgetItem>{leftThickness}</WidgetItem>
                  </WidgetRow>
                  <WidgetRow>
                    <WidgetItem style={{ width: '90px', marginRight: '15px' }}>
                      Right
                    </WidgetItem>
                    <WidgetItem>{rightThickness}</WidgetItem>
                  </WidgetRow>
                </Widget>
              </Right>
            </TwoCol>
            <View>
              <ImgWrapper src={Image1} />
            </View>
          </View>
        </PageTemplate>
      )}
      {hasGlass && (
        <PageTemplate number="9.2" title="End user considerations">
          <View>
            <TwoCol>
              <Left>
                <H2>Balcony privacy: glass</H2>
                <P>
                  Satin pre-etched or laminate glass is a cost effective and
                  durable solution, which creates privacy but still allows light
                  through. It is important to consider the fixing options, and
                  consider the gaps created by different fixing options.
                </P>
              </Left>
              <Right>
                <Widget>
                  <WidgetHeading>Your balcony design</WidgetHeading>
                  <WidgetRow>
                    <WidgetItem style={{ width: '90px', marginRight: '15px' }}>
                      Left
                    </WidgetItem>
                    <WidgetItem>{leftGlass}</WidgetItem>
                  </WidgetRow>
                  <WidgetRow>
                    <WidgetItem style={{ width: '90px', marginRight: '15px' }}>
                      Right
                    </WidgetItem>
                    <WidgetItem>{rightGlass}</WidgetItem>
                  </WidgetRow>
                  <WidgetRow>
                    <WidgetItem style={{ width: '90px', marginRight: '15px' }}>
                      Front
                    </WidgetItem>
                    <WidgetItem>{frontGlass}</WidgetItem>
                  </WidgetRow>
                </Widget>
              </Right>
            </TwoCol>
            <ImageCols>
              <View style={{ marginRight: '15px' }}>
                <ImgWrapperAlt src={Image2} />
              </View>
              <View>
                <ImgWrapperAlt src={Image3} />
              </View>
            </ImageCols>
          </View>
        </PageTemplate>
      )}
    </>
  );
};
