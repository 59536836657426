import React from 'react';
import {
  PrimarySectionWrapper,
  PrimaryItem,
} from './mobileConfigurator.styles';
import ICONS from '../../../assets/icons';

const PrimarySection = ({
  show,
  selected,
  onClickBackground,
  onSelect,
  configurator,
}) => {
  return (
    <PrimarySectionWrapper expand={show} onClick={onClickBackground}>
      {configurator.map((section, i) => (
        <PrimaryItem
          key={i}
          onClick={e => {
            onSelect(i);
            e.stopPropagation();
          }}
          selected={selected === i}
        >
          <div>
            <div>
              <div>
                {section.icon && (
                  <img src={ICONS[section.icon]} alt={section.icon} />
                )}
              </div>
            </div>
          </div>
          <div>
            <div>{section.label}</div>
          </div>
        </PrimaryItem>
      ))}
    </PrimarySectionWrapper>
  );
};

export default PrimarySection;
