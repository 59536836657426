import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import {
  TertiarySectionWrapper,
  SectionTitle as Title,
  SectionContent as Content,
  TertiaryItem as Item,
  TertiarySubitem as Subitem,
} from './mobileConfigurator.styles';
import InputComponents from '../../InputComponents';

const TertiarySection = ({ show, address, configurator }) => {
  const state = useContext(AppContext);
  const [primaryIdx, secondaryIdx] = address;
  const parent = state.data[primaryIdx].sections[secondaryIdx];

  const handleUpdateSection = (parent, section) => {
    if (state && state.configuration[parent.attribute] !== section.value) {
      if (section.validation) {
        const updateObj = section.override
          ? { ...section.override, [parent.attribute]: section.value }
          : { [parent.attribute]: section.value };
        const valid = section.validation(updateObj, state.configuration);
        if (!valid) return;
      }

      state.setAttribute(parent.attribute, section.value);
      if (
        section.override &&
        state.configuration[parent.attribute] !== section.value
      ) {
        for (const attribute in section.override) {
          state.setAttribute(attribute, section.override[attribute]);
        }
      }
    }
  };

  const handleUpdateInput = (validation, attribute, value) => {
    if (validation) {
      const valid = validation({ [attribute]: value }, state.configuration);
      if (!valid) return;
    }
    state.setAttribute(attribute, value);
  };

  return (
    <TertiarySectionWrapper expand={show}>
      {configurator && (
        <Content>
          <Title>{configurator.label}</Title>

          {configurator.sections.length
            ? Object.values(configurator.sections).map((section, i) => (
                <Item
                  key={i}
                  selected={
                    !!(
                      state &&
                      section.value === state.configuration[parent.attribute]
                    )
                  }
                  onClick={() => handleUpdateSection(parent, section)}
                >
                  <div>
                    <div>{section.label}</div>
                  </div>
                  {section.inputs &&
                    section.inputs.length > 0 &&
                    Object.values(section.inputs).map((input, j) => (
                      <Subitem key={input.id}>
                        {input &&
                          (!input.dependOnAttribute ||
                            (Array.isArray(input.dependOnValue)
                              ? input.dependOnValue
                              : [input.dependOnValue]
                            ).includes(
                              state.configuration[input.dependOnAttribute]
                            )) && (
                            <InputComponents
                              {...input}
                              configuration={
                                state.configuration[input.attribute]
                              }
                              onChange={value =>
                                handleUpdateInput(
                                  input.validation || null,
                                  input.attribute,
                                  value
                                )
                              }
                            />
                          )}
                      </Subitem>
                    ))}
                </Item>
              ))
            : null}
          {configurator.inputs && configurator.inputs.length
            ? Object.values(configurator.inputs).map(
                (input, i) =>
                  (!input.dependOnAttribute ||
                    (Array.isArray(input.dependOnValue)
                      ? input.dependOnValue
                      : [input.dependOnValue]
                    ).includes(
                      state.configuration[input.dependOnAttribute]
                    )) && (
                    <InputComponents
                      key={i}
                      {...input}
                      configuration={state.configuration[input.attribute]}
                      onChange={value =>
                        handleUpdateInput(
                          input.validation || null,
                          input.attribute,
                          value
                        )
                      }
                    />
                  )
              )
            : null}
        </Content>
      )}
    </TertiarySectionWrapper>
  );
};

export default TertiarySection;
