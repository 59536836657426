import fadedEbonyGrey from './faded-ebony-grey.jpg';
import oldMahoganyBrown from './old-mahogany-brown.jpg';
import sandedOakBrown from './sanded-oak-brown.jpg'
import weatheredAshGrey from './weathered-ash-grey.jpg'
import willowTreeGreen from "./willow-tree-green.jpg"

export default {
  'Quartz Grey': fadedEbonyGrey,
  'Anthracite': fadedEbonyGrey,
  'Grey Brown': oldMahoganyBrown,
  'Sepia Brown': sandedOakBrown,
  'Dusty Grey': weatheredAshGrey,
  'Window Grey': willowTreeGreen
};
