import styled from 'styled-components';
import { MEDIA_QUERY } from '../../../constants';

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
  height: 40px;
  padding-left: 8px;
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 12px;
  cursor: pointer;
  letter-spacing: 0.4px;
  border-bottom: 1px solid #cccccc;

  ${(props) =>
    props.selected &&
    `color: ${props.theme.desktop.tertiary.fontSelectedColor}; font-weight: 600;`}

  & > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  & > div:nth-child(1) {
    height: 16px;

    & > div {
      height: 16px;
      width: 16px;
      border: 1px solid
        ${(props) => props.theme.desktop.tertiary.fontDefaultColor};
      border-radius: 50%;

      & > div {
        height: calc(100% - 4px);
        width: calc(100% - 4px);
        margin: 2px;
        border-radius: 50%;
        ${(props) =>
          props.selected && `background: ${props.theme.colors.selected};`}
      }
    }
  }

  & > div:nth-child(2) {
    height: 20px;
  }
`;

export const ImageButtonWrapper = styled.div`
  @media ${MEDIA_QUERY.mobile} {
    height: 50px;
  }

  @media ${MEDIA_QUERY.tabletP} {
    height: 70px;
  }

  padding: 0 12px;
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 15px;
  cursor: pointer;
  border-bottom: 1px solid #e4e4e4;

  ${(props) => props.selected && 'color: #1890ff;'}

  & > div {
    height: max-content;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  & > div:nth-child(1) {
    height: 52px;
    width: 52px;
    border-radius: 3px;
    overflow: hidden;
    img {
      height: 100%;
      object-fit: fit-content;
    }
  }
`;

export const Title = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
`;

export const Description = styled.div`
  margin-bottom: 12px;
`;
