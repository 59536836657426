const prepSectionWhiteLabelling = (
  sections,
  parentWhiteLabel,
  whiteLabellingAttrs
) => {
  if (!sections || sections.length === 0) return [];
  if (!parentWhiteLabel || !whiteLabellingAttrs[parentWhiteLabel])
    return sections;

  return sections.filter((section) =>
    whiteLabellingAttrs[parentWhiteLabel].values.includes(section.value)
  );
};

const prepInputsWhiteLabelling = (inputs, whiteLabellingAttrs) => {
  if (!inputs || inputs.length === 0) return [];

  return inputs.map((input) => {
    if (
      input.input !== 'RadioButtons' ||
      !input.whitelabel ||
      !whiteLabellingAttrs[input.whitelabel]
    )
      return input;

    if (whiteLabellingAttrs[input.whitelabel].type === 'Asset')
      return {
        ...input,
        data: {
          ...input.data,
          options: whiteLabellingAttrs[input.whitelabel].values,
        },
      };

    return {
      ...input,
      data: {
        ...input.data,
        options: input.data.options.filter((opt) =>
          whiteLabellingAttrs[input.whitelabel].values.includes(opt.value)
        ),
      },
    };
  });
};

export const prepConfiguratorState = (
  configurator,
  inputsData,
  validationData,
  whiteLabellingAttrs
) => {
  if (!configurator || !inputsData) return [];
  return configurator.map((configuratorItem) => {
    if (
      (!configuratorItem.sections || configuratorItem.sections.length === 0) &&
      (!configuratorItem.inputs || configuratorItem.inputs.length === 0) &&
      !configuratorItem.validation
    )
      return configuratorItem;

    const inputs =
      configuratorItem.inputs &&
      configuratorItem.inputs.map((inputId) => ({
        ...inputsData[inputId],
        value: inputsData[inputId] ? inputsData[inputId].default : null,
        validation:
          inputsData[inputId] && inputsData[inputId].validation
            ? validationData[inputsData[inputId].validation]
            : null,
      }));

    const validation =
      configuratorItem.validation && validationData[configuratorItem.validation]
        ? validationData[configuratorItem.validation]
        : null;

    const sections = prepConfiguratorState(
      prepSectionWhiteLabelling(
        configuratorItem.sections,
        configuratorItem.whitelabel,
        whiteLabellingAttrs
      ),
      inputsData,
      validationData,
      whiteLabellingAttrs
    );

    return {
      ...configuratorItem,
      sections,
      inputs: prepInputsWhiteLabelling(inputs, whiteLabellingAttrs),
      validation,
      selected: null,
    };
  });
};

export const trapeziumArea = (height, width, angleL, angleR) => {
  return parseFloat(
    (width * 2 -
      height / Math.tan((angleL * Math.PI) / 180) +
      height / Math.tan((angleR * Math.PI) / 180)) *
      0.5 *
      height
  ).toFixed(2);
};

const getOffset = (pos, configuration) => {
  const thickness = {
    glass: 21.5,
    bars: 50,
    fascia: 20,
  };

  if (configuration[`${pos} Type`] === 'Solid Wall')
    return Number(configuration[`${pos} Wall Thickness`].slice(0, -3));
  if (configuration[`${pos} Type`] === 'No Balustrade') return 0;
  if (configuration[`${pos} Type`] === 'Vertical Bars')
    switch (configuration[`${pos} Vertical Bars Fascia`]) {
      case 'In Front':
        return thickness.bars + thickness.fascia;
      case 'Behind':
        return thickness.bars;
      default:
    }
  if (configuration[`${pos} Type`] === 'Structural Glass')
    switch (configuration[`${pos} Glass Fascia`]) {
      case 'Fascia Trim':
        return thickness.glass + thickness.fascia;
      case 'Printed Band':
        return thickness.glass;
      default:
    }
};

export const getOutsideProjection = (configuration) =>
  Number(configuration.Projection) + getOffset('Front', configuration);

export const getOutsideWidth = (configuration) =>
  Number(configuration.Width) +
  getOffset('Left', configuration) +
  getOffset('Right', configuration);
