import React from 'react';
import { H2, P } from '../../shared.styles';
import { Image, View, Text } from '@react-pdf/renderer';
import { Left, Right, TableHeading, TableRow } from './page41.styles';
import data from './data.json';

import PageTemplate from '../../Templates/PageTemplate';

import Image1 from '../../assets/page41_01.jpg';
import imgCheck from '../../assets/check.png';

export default () => {
  return (
    <PageTemplate number="4.1" title="Structural load summary">
      <Left>
        {data.sections.map(section => (
          <>
            <H2>{section.title}</H2>
            {section.paragraphs.map(p => (
              <P>{p}</P>
            ))}
          </>
        ))}
        <View>
          <TableHeading>
            {data.table.columns.map((el, i) => {
              let width = i === 0 ? '150px' : '45px';
              let marginLeft = i === 0 ? 0 : '12px';
              return (
                <View style={{ width, textAlign: 'center', marginLeft }}>
                  <Text>{el}</Text>
                </View>
              );
            })}
            <View
              style={{
                width: '25px',
                textAlign: 'center',
                marginLeft: '10px',
              }}
            >
              <Text>Pass</Text>
            </View>
          </TableHeading>
          {data.table.data.map((el, i) => {
            let background = i % 2 === 0 ? 'light' : 'dark';
            return (
              <TableRow background={background}>
                {el.map((item, j) =>
                  j === 0 ? (
                    <View style={{ width: '150px', textAlign: 'left' }}>
                      <Text style={{ fontFamily: 'MyriadProBold' }}>
                        {item.heading}
                      </Text>
                      <Text style={{ fontSize: '6pt' }}>{item.data}</Text>
                    </View>
                  ) : (
                    <View
                      style={{
                        width: '45px',
                        textAlign: 'center',
                        marginLeft: '12px',
                      }}
                    >
                      <Text>{item}</Text>
                    </View>
                  )
                )}
                <View
                  style={{
                    width: '25px',
                    textAlign: 'center',
                    marginLeft: '10px',
                    paddingLeft: '8px',
                    paddingTop: '2px',
                  }}
                >
                  <Image
                    src={imgCheck}
                    style={{
                      height: '10px',
                      width: '10px',
                      objectFit: 'contain',
                    }}
                  />
                </View>
              </TableRow>
            );
          })}
        </View>
      </Left>
      <Right>
        <Image src={Image1} />
      </Right>
    </PageTemplate>
  );
};
