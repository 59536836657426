import React from 'react';
import { View, Image } from '@react-pdf/renderer';
import {
  Left,
  Right,
  TwoCol,
  SubLeft,
  SubRight,
  WidgetHeading,
} from './page101.styles';
import {
  WidgetAlt as Widget,
  WidgetItemAlt as WidgetItem,
} from '../../shared.styles';

import PageTemplate from '../../Templates/PageTemplate';

import Image1 from '../../assets/page101_01.jpg';
import Image2 from '../../assets/page101_02.jpg';
import Image3 from '../../assets/page101_03.jpg';
import Image4 from '../../assets/page101_04.jpg';

export default () => {
  return (
    <PageTemplate number="10.1" title="Precedent project images & case studies">
      <Left>
        <View>
          <Image src={Image1} />
        </View>
      </Left>
      <Right>
        <View>
          <Image src={Image2} />
        </View>
        <TwoCol>
          <SubLeft>
            <Image src={Image3} />
          </SubLeft>
          <SubRight>
            <Widget style={{ marginBottom: '10px' }}>
              <WidgetHeading>Case Studies</WidgetHeading>
              <WidgetItem>Greenwich Peninsula »</WidgetItem>
              <WidgetItem>Carnarvon »</WidgetItem>
            </Widget>
            <Image src={Image4} />
          </SubRight>
        </TwoCol>
      </Right>
    </PageTemplate>
  );
};
