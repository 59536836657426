import React from 'react';
import { View, Image } from '@react-pdf/renderer';
import { Left, Right, TwoCol, SubLeft, SubRight } from './page104.styles';
import {
  Sidebar,
  WidgetAlt as Widget,
  WidgetHeadingAlt as WidgetHeading,
  WidgetItemAlt as WidgetItem,
} from '../../shared.styles';

import PageTemplate from '../../Templates/PageTemplate';

import Image1 from '../../assets/page104_01.jpg';
import Image2 from '../../assets/page104_02.jpg';
import Image3 from '../../assets/page104_03.jpg';
import Image4 from '../../assets/page104_04.jpg';
import Image5 from '../../assets/page104_05.jpg';

export default () => {
  return (
    <PageTemplate number="10.1" title="Precedent project images & case studies">
      <Left>
        <TwoCol>
          <SubLeft>
            <Image src={Image2} />
          </SubLeft>
          <SubRight>
            <Image src={Image3} />
          </SubRight>
        </TwoCol>
        <View>
          <Image src={Image4} />
        </View>
      </Left>
      <Right>
        <View>
          <Image src={Image5} />
        </View>
      </Right>
      <Sidebar>
        <Widget style={{ marginBottom: '14px' }}>
          <WidgetHeading>Case studies</WidgetHeading>
          <WidgetItem>Wembley Alto »</WidgetItem>
          <WidgetItem>London Dock »</WidgetItem>
          <WidgetItem>Pomona Wharf »</WidgetItem>
          <WidgetItem>Royal Arsenal »</WidgetItem>
          <WidgetItem>First Central »</WidgetItem>
          <WidgetItem>North Wharf Gardens »</WidgetItem>
          <WidgetItem>Greenwhich Peninsula (GP103) »</WidgetItem>
          <WidgetItem>Kingston Gas Works »</WidgetItem>
        </Widget>
        <Image src={Image1} />
      </Sidebar>
    </PageTemplate>
  );
};
