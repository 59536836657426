import styled from '@react-pdf/styled-components';
import { COLORS } from '../../constants';

export const Left = styled.View`
  /* width: 310px; */
  margin-right: 20px;
`;

export const HeadingWrapper = styled.View`
  height: 30px;
  margin-bottom: 15px;
`;

export const Heading = styled.Text`
  font-weight: 600;

  font-size: 19pt;
  font-family: 'MyriadPro';
  color: ${COLORS.green};
  letter-spacing: -0.5pt;
`;

export const TwoCol = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SubLeft = styled.View``;

export const SubRight = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Right = styled.View`
  /* width: 300px; */
`;

export const RightRow = styled.View`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
`;

export const ConnectionWrapper = styled.View`
  border: 2px solid ${COLORS.altGreen};
  margin-bottom: 10px;
`;

export const StructureWrapper = styled.View`
  border: 2px solid ${COLORS.altSalmon};
`;

export const BalustradeWrapper = styled.View`
  border: 2px solid ${COLORS.altBlue};
`;

export const ConnectionHeading = styled.View`
  background: ${COLORS.altGreen};
  color: white;
  padding: 2px;
  font-family: 'MyriadProBold';
  font-size: 9pt;
`;

export const StructureHeading = styled.View`
  background: ${COLORS.altSalmon};
  color: white;
  padding: 2px;
  font-family: 'MyriadProBold';
  font-size: 9pt;
`;

export const BalustradeHeading = styled.View`
  background: ${COLORS.altBlue};
  color: white;
  padding: 2px;
  font-family: 'MyriadProBold';
  font-size: 9pt;
`;

export const Item = styled.View`
  display: flex;
  flex-direction: row;
  padding: 5px;
`;

export const ItemAlt = styled.View`
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 140px;
`;

export const Image = styled.Image`
  height: 80px;
  width: 70px;
  margin-right: 8px;

  object-fit: cover;
`;

export const ImageAlt = styled.Image`
  /* height: 80px; */
  width: 80px;
  margin-right: 8px;

  object-fit: contain;
  object-position: 0 0;
`;

export const H3 = styled.Text`
  font-family: 'MyriadProBold';
  font-size: 8pt;
`;
