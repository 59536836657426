import glass from './glass-balustrade.jpg';
import none from './no-balustrade.jpg';
import solid from './solid-balustrade.jpg';
import bars from './vertical-bars.jpg';

export default {
  'Structural Glass': glass,
  'No Balustrade': none,
  'Solid Wall': solid,
  'Vertical Bars': bars,
  'Module Panel': solid,
};
