import React, { useState, useContext } from 'react';
import { Input, Button, message } from 'antd';
import { Wrapper, Background } from './formModal.styles';
import { AppContext } from '../../context';

const FormModal = ({ handleClose, allowDownload, setLoading, loading }) => {
  const { user, setUser, pdfUrl } = useContext(AppContext);

  const [name, setName] = useState(user.name || '');
  const [email, setEmail] = useState(user.email || '');
  const [company, setCompany] = useState(user.company || '');
  const [share, setShare] = useState(user.share || '');
  const [project, setProject] = useState(
    user.project || 'Balcony Configuration'
  );

  const handleClickSave = () => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email.toLowerCase()))
      return message.warning('Invalid email address');
    if (share.length && !re.test(share.toLowerCase()))
      return message.warning('Invalid share email address');
    setLoading(true);
    setUser({ name, email, share, company, project }, () => setLoading(false));
    message.success('Email sent!');
    !allowDownload && handleClose();
  };

  const handleClickPDF = () => {
    if (!pdfUrl) return;
    window.open(pdfUrl);
  };

  return (
    <>
      <Background onClick={handleClose} />
      <Wrapper>
        <h3>Save your balcony design</h3>
        <h4>Name:</h4>
        <Input value={name} onChange={e => setName(e.target.value)} />
        <h4>E-mail:</h4>
        <Input value={email} onChange={e => setEmail(e.target.value)} />
        <h4>Company Name:</h4>
        <Input value={company} onChange={e => setCompany(e.target.value)} />
        <h4>Project Name:</h4>
        <Input value={project} onChange={e => setProject(e.target.value)} />
        <h4>Share with (optional):</h4>
        <Input value={share} onChange={e => setShare(e.target.value)} />
        <div>
          <Button onClick={handleClose} style={{ marginRight: '15px' }}>
            Close
          </Button>
          {pdfUrl ? (
            <Button
              type="primary"
              style={{ marginRight: '15px' }}
              onClick={handleClickPDF}
            >
              View PDF
            </Button>
          ) : (
            <Button
              disabled={!(name && email && company)}
              loading={loading}
              type="primary"
              onClick={handleClickSave}
            >
              {loading ? 'Prepping PDF' : 'Save'}
            </Button>
          )}
        </div>
      </Wrapper>
    </>
  );
};

export default FormModal;
