import styled from '@react-pdf/styled-components';
import { COLORS } from '../../constants';

export const Left = styled.View`
  width: 460px;
  margin-right: 15px;
`;

export const Right = styled.View`
  width: 150px;
`;

export const Heading = styled.Text`
  font-weight: 600;
  margin-bottom: 15px;

  font-size: 19pt;
  font-family: 'MyriadPro';
  color: ${COLORS.green};
  letter-spacing: -0.5pt;
`;

export const TableRow = styled.View`
  display: flex;
  flex-direction: row;
  background: ${props =>
    props.background === 'light' ? COLORS.lightGray : COLORS.darkGray};
  padding: 5px 10px 3px 10px;

  font-size: 8pt;
  font-family: 'MyriadPro';
`;

export const ImagesRow = styled.View`
  display: flex;
  flex-direction: row;

  margin-top: 20px;
`;

export const Image = styled.Image`
  height: 200px;
  width: 220px;

  object-fit: cover;
`;
