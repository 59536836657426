import presets from './icon-presets.png';
import connection from './icon-connection.png';
import balustrade from './icon-balustrade.png';
import cassette from './icon-cassette.png';
import colour from './icon-colour.png';
import summary from './icon-summary.png';

export default {
  balustrade,
  connection,
  cassette,
  colour,
  presets,
  summary,
};
