import React from 'react';
import { Document } from '@react-pdf/renderer';
import './fonts';
import { trapeziumArea, getOutsideProjection, getOutsideWidth } from '../utils';

// Title Images
import PageImg10 from './assets/page10.jpg';
import PageImg20 from './assets/page20.jpg';
import PageImg30 from './assets/page30.jpg';
import PageImg40 from './assets/page40.jpg';
import PageImg50 from './assets/page50.jpg';
import PageImg60 from './assets/page60.jpg';
import PageImg70 from './assets/page70.jpg';
import PageImg80 from './assets/page80.jpg';
import PageImg90 from './assets/page90.jpg';
import PageImg100 from './assets/page100.jpg';

import CoverPage from './Pages/CoverPage';
import SectionTitle from './Templates/SectionTitle';
import TableOfContents from './Pages/TableOfContents';
import Page11 from './Pages/1.1';
import Page21 from './Pages/2.1';
import Page31 from './Pages/3.1';
import Page32 from './Pages/3.2';
import Page41 from './Pages/4.1';
import Page51 from './Pages/5.1';
import Page61 from './Pages/6.1';
import Page71 from './Pages/7.1';
import Page81 from './Pages/8.1';
import Page91 from './Pages/9.1';
import Page92 from './Pages/9.2';
import Page101 from './Pages/10.1';
import Page102 from './Pages/10.2';
import Page103 from './Pages/10.3';
import Page104 from './Pages/10.4';
import LastPage from './Pages/LastPage';

// Create Document Component
const MyDocument = ({ data }) => {
  const width = data['Width'] / 1000;
  const maxWidth = getOutsideWidth(data) / 1000;
  const height = data['Projection'] / 1000;
  const maxHeight = getOutsideProjection(data) / 1000;
  const angleL = data['Left Angle'];
  const angleR = data['Right Angle'];
  const area = trapeziumArea(height, width, angleL, angleR);
  const maxArea = trapeziumArea(maxHeight, maxWidth, angleL, angleR);

  const balustradeSet = new Set([
    data['Front Type'],
    data['Left Type'],
    data['Right Type'],
  ]);

  const balustradeOptions = Array.from(balustradeSet);

  return (
    <Document>
      <CoverPage data={data} />
      <TableOfContents />
      <SectionTitle
        number="1.0"
        title="Balcony specification overview"
        imgUrl={PageImg10}
      />
      <Page11
        project={data.project}
        projection={data['Projection'] / 1000}
        rear={data['Width'] / 1000}
        area={area}
        maxArea={maxArea}
        height={data['Front Height']}
        drainage={data['Drainage'].replace(' Drainage', '')}
        snapshot={[data.snapshots[0], data.snapshots[1]]}
      />
      <SectionTitle number="2.0" title="Material Choice" imgUrl={PageImg20} />
      <Page21
        project={data.project}
        anchor={data['Anchor']}
        drainage={data['Drainage']}
        deckingColor={data['Decking Colour']}
        balustradeFront={data['Front Type']}
        balustradeLeft={data['Left Type']}
        balustradeRight={data['Right Type']}
        frontGlassType={data['Front Glass Type']}
        toprail={data['Front Toprail']}
        fasciaOptions={Array.from(
          new Set([
            data['Front Glass Fascia'],
            data['Right Glass Fascia'],
            data['Left Glass Fascia'],
          ])
        )}
      />
      <SectionTitle
        number="3.0"
        title="Rigidity considerations"
        imgUrl={PageImg30}
      />
      <Page31 projection={data['Projection']} width={data['Width']} />
      <Page32
        anchor={data['Anchor']}
        floorThickness={data[`${data['Anchor']} Floor Thickness`]}
        slabThickness={data[`${data['Anchor']} Slab Thickness`]}
      />
      <SectionTitle
        number="4.0"
        title="Structural load summary"
        imgUrl={PageImg40}
      />
      <Page41 />
      <SectionTitle number="5.0" title="NBS specification" imgUrl={PageImg50} />
      <Page51
        balustradeOpts={balustradeOptions}
        balustradeLeft={data['Left Type']}
        balustradeRight={data['Right Type']}
        balustradeFront={data['Front Type']}
        frontGlassFascia={data['Front Glass Fascia']}
        leftGlassFascia={data['Left Glass Fascia']}
        rightGlassFascia={data['Right Glass Fascia']}
        frontBarsFascia={data['Front Vertical Bars Fascia']}
        leftBarsFascia={data['Left Vertical Bars Fascia']}
        rightBarsFascia={data['Right Vertical Bars Fascia']}
        toprailOptions={Array.from(
          new Set([
            data['Front Toprail'],
            data['Right Toprail'],
            data['Left Toprail'],
          ])
        )}
        hasRoundToprail={[
          data['Front Toprail'],
          data['Right Toprail'],
          data['Left Toprail'],
        ].includes('Round Handrail')}
        balconyColor={data['Balcony Colour']}
        drainageOpt={data['Drainage']}
        deckingColor={data['Decking Colour']}
        frontRailing={data['Front Toprail']}
        fireClass={data['Fire Class']}
        fasciaPosition={data['Front Vertical Bars Fascia']}
        fasciaOptions={Array.from(
          new Set([
            data['Front Glass Fascia'],
            data['Right Glass Fascia'],
            data['Left Glass Fascia'],
          ])
        )}
      />
      <SectionTitle number="6.0" title="Child safety" imgUrl={PageImg60} />
      <Page61 />
      <SectionTitle
        number="7.0"
        title="Fire considerations summary"
        imgUrl={PageImg70}
      />
      <Page71 fireClass={data['Fire Class']} />
      <SectionTitle
        number="8.0"
        title="Drainage considerations"
        imgUrl={PageImg80}
      />
      <Page81 area={area} drainage={data['Drainage']} />
      <SectionTitle
        number="9.0"
        title="End user considerations"
        imgUrl={PageImg90}
      />
      <Page91 projection={data['Projection']} width={data['Width']} />
      <Page92
        leftThickness={data['Left Wall Thickness']}
        rightThickness={data['Right Wall Thickness']}
        leftGlass={data['Left Glass Type']}
        rightGlass={data['Right Glass Type']}
        frontGlass={data['Front Glass Type']}
        hasGlass={balustradeOptions.includes('Structural Glass')}
        hasWall={balustradeOptions.includes('Solid Wall')}
      />
      <SectionTitle
        number="10.0"
        title="Precedent project images & case studies"
        imgUrl={PageImg100}
      />
      {data['Front Type'] === 'Vertical Bars' ? (
        balustradeSet.has('Solid Wall') ? (
          <Page101 />
        ) : (
          <Page102 />
        )
      ) : balustradeSet.has('Solid Wall') ? (
        <Page103 />
      ) : (
        <Page104 />
      )}
      <LastPage />
    </Document>
  );
};

export default MyDocument;
