import styled from '@react-pdf/styled-components';

export const H3 = styled.Text`
  font-family: 'MyriadProBold';
  font-size: 8px;
  margin-bottom: 8px;
`;

export const Item = styled.View`
  display: flex;
  flex-direction: row;

  font-family: 'MyriadPro';
  font-size: 8px;
  margin-bottom: 5px;
`;
