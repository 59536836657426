import styled from '@react-pdf/styled-components';
import { COLORS } from './constants';

export const H2 = styled.Text`
  font-family: 'MyriadProBold';
  font-size: 9pt;
  margin-bottom: 15px;
`;

export const P = styled.Text`
  font-family: 'MyriadPro';
  font-size: 9pt;
  line-height: 1.6pt;
  margin-bottom: 4px;
`;

export const ListItem = styled.Text`
  font-family: 'MyriadPro';
  font-size: 9pt;
  line-height: 1.6pt;
`;

export const Widget = styled.View`
  background: ${COLORS.blue};
  padding: 5px 5px 0 5px;
`;

export const WidgetAlt = styled.View`
  background: lightgray;
  padding: 5px 5px 0 5px;
`;

export const WidgetRow = styled.View`
  display: flex;
  flex-direction: row;
`;

export const WidgetHeading = styled.Text`
  color: white;
  font-size: 9pt;
  font-family: 'MyriadProBold';

  margin-bottom: 15px;
`;

export const WidgetHeadingAlt = styled.Text`
  font-size: 9pt;
  font-family: 'MyriadProBold';

  margin-bottom: 15px;
`;

export const WidgetItem = styled.Text`
  color: white;
  font-size: 9pt;
  font-family: 'MyriadPro';

  margin-bottom: 5px;
`;

export const WidgetItemAlt = styled.Text`
  font-size: 9pt;
  font-family: 'MyriadPro';

  margin-bottom: 5px;
`;

export const Caption = styled.Text`
  font-family: 'MyriadPro';
  font-size: 8pt;
  line-height: 1.3px;
`;

export const Chip = styled.View`
  position: absolute;
  left: 80%;
`;

export const Sidebar = styled.View`
  width: 150px;
  position: absolute;
  left: -160px;
  bottom: 0px;
`;
