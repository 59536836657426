import React from 'react';
import DesktopConfigurator from './DesktopConfigurator';
import MobileConfigurator from './MobileConfigurator';
import { MEDIA_QUERY } from '../../constants';
import { useMediaQuery } from 'react-responsive';

const Configurator = () => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERY.mobile });
  // const isDesktop = useMediaQuery({ query: MEDIA_QUERY.desktop });

  return isMobile ? <MobileConfigurator show /> : <DesktopConfigurator show />;
};

export default Configurator;
