import styled from '@react-pdf/styled-components';

export const Left = styled.View`
  width: 310px;
  margin-right: 10px;
`;

export const Right = styled.View`
  width: 310px;
`;

export const TwoCol = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 14px;
`;

export const SubLeft = styled.View`
  width: 150px;
  margin-right: 10px;
`;

export const SubRight = styled.View`
  width: 150px;
`;

export const WidgetHeading = styled.Text`
  font-size: 9pt;
  font-family: 'MyriadProBold';

  margin-bottom: 7px;
`;
