import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import {
  Wrapper,
  ImageButtonWrapper,
  ButtonWrapper,
  Title,
  Description,
} from './radioButtons.styles';
import icnImgMissing from '../../../assets/no-image.webp';

const RadioButtons = ({
  label,
  description,
  images,
  options,
  value,
  onChange,
  configuration,
}) => {
  const state = useContext(AppContext);
  return (
    <>
      {options && (
        <Wrapper>
          {label && <Title>{label}</Title>}
          {description && <Description>{description}</Description>}
          {options
            .filter(
              (option) =>
                !option.dependOnAttribute ||
                state.configuration[option.dependOnAttribute] ===
                  option.dependOnValue
            )
            .map((option, i) =>
              images ? (
                <ImageButtonWrapper
                  key={i}
                  selected={option.value === configuration}
                  onClick={() => onChange(option.value)}
                >
                  <div>
                    <img
                      src={option.imgUrl || icnImgMissing}
                      alt={option.label || undefined}
                    />
                  </div>
                  <div>{option.label}</div>
                </ImageButtonWrapper>
              ) : (
                <ButtonWrapper
                  key={i}
                  selected={
                    typeof configuration === 'object'
                      ? option.value === configuration.assetId
                      : option.value === configuration
                  }
                  onClick={() => onChange(option.value)}
                >
                  <div>
                    <div>
                      <div />
                    </div>
                  </div>
                  <div>{option.label}</div>
                </ButtonWrapper>
              )
            )}
        </Wrapper>
      )}
    </>
  );
};

export default RadioButtons;
