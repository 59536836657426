import React from 'react';
import { View } from '@react-pdf/renderer';
import {
  H2,
  P,
  Widget,
  WidgetRow,
  WidgetHeading,
  WidgetItem,
  Caption,
} from '../../shared.styles';
import {
  TwoCol,
  Left,
  Right,
  ImgWrapper,
  ImgWrapperAlt,
} from './page81.styles';

import data1 from './data1.json';
import data2 from './data2.json';

import PageTemplate from '../../Templates/PageTemplate';

import Image1 from '../../assets/page81_01.png';
import Image2 from '../../assets/page81_02.png';
import Image3 from '../../assets/page81_03.png';

export default ({ area, drainage }) => {
  return (
    <>
      {drainage === 'Controlled Drainage' && (
        <PageTemplate number="8.1" title="Drainage considerations">
          <View>
            <TwoCol>
              <Left>
                {data1.sections.map((section) => (
                  <>
                    <H2>{section.title}</H2>
                    {section.paragraphs.map((p) => (
                      <P>{p}</P>
                    ))}
                  </>
                ))}
                <View>
                  <ImgWrapper src={Image1} />
                </View>
              </Left>
              <Right>
                <Widget>
                  <WidgetHeading>Your balcony design</WidgetHeading>
                  <WidgetRow>
                    <WidgetItem style={{ width: '90px', marginRight: '15px' }}>
                      Square meterage of your balcony
                    </WidgetItem>
                    <WidgetItem>{area} m2</WidgetItem>
                  </WidgetRow>
                </Widget>
                <View style={{ marginTop: '15px' }}>
                  <ImgWrapperAlt src={Image3} />
                </View>
                <View style={{ marginTop: '5px' }}>
                  <Caption>{data1.captions.image1}</Caption>
                </View>
              </Right>
            </TwoCol>
          </View>
        </PageTemplate>
      )}
      {drainage === 'Positive Drainage' && (
        <PageTemplate number="8.1" title="Drainage considerations">
          <View>
            <TwoCol>
              <Left>
                {data2.sections.map((section) => (
                  <>
                    <H2>{section.title}</H2>
                    {section.paragraphs.map((p) => (
                      <P>{p}</P>
                    ))}
                  </>
                ))}
                <View>
                  <ImgWrapper src={Image2} />
                </View>
              </Left>
              <Right>
                <Widget style={{ marginTop: '15px' }}>
                  <WidgetHeading>Your balcony design</WidgetHeading>
                  <WidgetRow>
                    <WidgetItem style={{ width: '90px', marginRight: '15px' }}>
                      Square meterage of your balcony
                    </WidgetItem>
                    <WidgetItem>{area} m2</WidgetItem>
                  </WidgetRow>
                </Widget>
                <View style={{ marginTop: '15px' }}>
                  <ImgWrapperAlt src={Image3} />
                </View>
                <View style={{ marginTop: '5px' }}>
                  <Caption>{data2.captions.image1}</Caption>
                </View>
              </Right>
            </TwoCol>
          </View>
        </PageTemplate>
      )}
    </>
  );
};
