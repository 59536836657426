import styled from '@react-pdf/styled-components';
import { COLORS } from '../../constants';

export const BackgroundWrapper = styled.View`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const BackgroundImgWrapper = styled.View`
  height: 25vh;
  width: 25vw;
`;
export const FooterWrapper = styled.View`
  position: absolute;
  top: 92vh;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FooterImgWrapper = styled.View`
  margin: 0 30px;

  height: 60px;
  width: 120px;
`;

export const SectionNumber = styled.View`
  position: absolute;
  top: 10vh;
  left: 30px;
`;

export const Heading = styled.Text`
  color: white;
  font-size: 31pt;
  letter-spacing: -1pt;
  font-family: 'NeoSansLight';
`;

export const Content = styled.View`
  position: absolute;
  width: 70vw;
  height: 65vh;
  left: 22vw;
  top: 10vh;

  font-size: 10px;
`;

export const TwoCol = styled.View`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.Text`
  margin-bottom: 40px;
  color: ${COLORS.blue};
  font-size: 31pt;
  letter-spacing: -1pt;
  font-family: 'NeoSansLight';
`;
