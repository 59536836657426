import styled from '@react-pdf/styled-components';

export const TwoCol = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const Left = styled.View`
  width: 300px;
  margin-right: 15px;
`;

export const Right = styled.View`
  width: 160px;
`;

export const Heading = styled.Text`
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const ImageCols = styled.View`
  display: flex;
  flex-direction: row;
  width: 475px;
`;

export const ImgWrapper = styled.Image`
  height: 300px;
  width: 475px;
  object-fit: contain;
`;

export const ImgWrapperAlt = styled.Image`
  height: 280px;
  width: 230px;
  object-fit: contain;
`;
