import React from 'react';
import Slider from './Slider';
import RadioButtons from './RadioButtons';
import ColorPicker from './ColorPicker';

const InputComponets = ({
  input,
  label,
  description,
  postscript,
  data,
  value,
  onChange,
  configuration,
}) => {
  switch (input) {
    case 'slider':
    case 'Slider':
      return (
        <Slider
          min={data.min}
          max={data.max}
          step={data.step}
          postscript={postscript}
          label={label}
          description={description}
          value={value}
          onChange={onChange}
          configuration={configuration}
        />
      );
    case 'radioButtons':
    case 'RadioButtons':
      return (
        <RadioButtons
          images={false}
          options={data.options}
          label={label}
          postscript={postscript}
          description={description}
          value={value}
          onChange={onChange}
          configuration={configuration}
        />
      );
    case 'imageRadioButtons':
    case 'ImageRadioButtons':
      return (
        <RadioButtons
          images
          options={data.options}
          label={label}
          postscript={postscript}
          description={description}
          value={value}
          onChange={onChange}
          configuration={configuration}
        />
      );
    case 'ColorPicker':
    case 'colorPicker':
      return (
        <ColorPicker
          label={label}
          description={description}
          onChange={onChange}
          value={value}
          configuration={configuration}
        />
      );
    default:
      return <div>No component for {input} was found</div>;
  }
};

export default InputComponets;
