import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import { Wrapper, Title, Description } from './colorPicker.styles';

const ColorPicker = ({ label, description, onChange, configuration }) => {
  return (
    <>
      {
        <Wrapper>
          {label && <Title>{label}</Title>}
          {description && <Description>{description}</Description>}
          {
            <div>
              <div>
                <input
                  type="color"
                  onChange={(v) => onChange(v.target.value)}
                  value={configuration}
                />
                <div />
              </div>
            </div>
          }
        </Wrapper>
      }
    </>
  );
};

export default ColorPicker;
