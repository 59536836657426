import React from 'react';
import { Image, View } from '@react-pdf/renderer';
import { H2, P, Caption } from '../../shared.styles';
import { Left, Right } from './page61.styles';

import data from './data.json';

import PageTemplate from '../../Templates/PageTemplate';

import Image1 from '../../assets/page61_01.jpg';
import Image2 from '../../assets/page61_02.jpg';

export default () => {
  return (
    <PageTemplate number="6.1" title="Child safety">
      <Left>
        {data.sections.map(section => (
          <>
            <H2>{section.title}</H2>
            {section.paragraphs.map(p => (
              <P>{p}</P>
            ))}
          </>
        ))}
      </Left>
      <Right>
        <View style={{ marginBottom: '5px' }}>
          <Image src={Image1} />
        </View>
        <Caption>{data.captions.image1}</Caption>
        <View style={{ marginTop: '15px', marginBottom: '5px' }}>
          <Image src={Image2} />
        </View>
        <Caption>{data.captions.image2}</Caption>
      </Right>
    </PageTemplate>
  );
};
