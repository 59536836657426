import React from 'react';
import {
  Primary,
  PrimaryItem,
  Secondary,
  SecondaryItem,
} from './desktopConfigurator.styles';
import ICONS from '../../../assets/icons';

const PrimarySecondarySection = ({
  configurator,
  selectedPrimary,
  selectedSecondary,
  expand,
  handleClickPrimary,
  handleClickSecondary,
}) => {
  return (
    <Primary className="scrollable">
      {Object.values(configurator).map((value, i) => (
        <PrimaryItem
          key={value.id}
          hasIcon={value.icon}
          selected={i === selectedPrimary}
          expand={expand}
          count={
            (value.sections &&
              value.sections.length > 1 &&
              value.sections.length) ||
            0
          }
        >
          <div onClick={() => handleClickPrimary(i)}>
            <div>
              {value.icon && (
                <img
                  src={ICONS[value.icon]}
                  alt={value.icon}
                  style={{ marginRight: '5px' }}
                />
              )}
            </div>
            <div>{value.label}</div>
          </div>

          {value.sections && value.sections.length > 1 && (
            <Secondary>
              {Object.values(value.sections).map((section, j) => (
                <SecondaryItem
                  key={section.id}
                  onClick={() => handleClickSecondary([i, j])}
                  selected={j === selectedSecondary}
                >
                  <div>
                    <hr />
                  </div>
                  <div>{section.label}</div>
                </SecondaryItem>
              ))}
            </Secondary>
          )}
        </PrimaryItem>
      ))}
    </Primary>
  );
};

export default PrimarySecondarySection;
