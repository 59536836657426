const sbTheme = {
  primary: 'rgb(0, 52, 120)', //  Blue
  secondary: 'rgb(122, 184, 0)', //  Lime Green
  rigid: 'rgb(81, 98, 111)', //  Grey
  ready: 'rgb(130, 36, 78)', //  Violet
  right: 'rgb(0, 115, 99)', //  Emerald
  selected: '#009ede',
  defaults: {
    fontDefaultColor: 'white',
    fontSelectedColor: 'white',
    fontHoverColor: 'white',
  },
};

export default {
  colors: { ...sbTheme },
  mobile: {
    primary: {
      ...sbTheme.defaults,
      fontDefaultColor: '#646464',
      defaultColor: '#fff',
      selectedColor: sbTheme.selected,
    },
    secondary: {
      ...sbTheme.defaults,
      fontDefaultColor: '#646464',
      fontSelectedColor: '#646464',
      defaultColor: '#f3f2f3',
      selectedColor: 'rgba(122, 184, 0, 0.8)',
    },
    tertiary: {
      ...sbTheme.defaults,
      defaultColor: 'white',
      selectedColor: 'rgba(0, 52, 120, 0.8)',
      fontDefaultColor: '#646464',
      fontSelectedColor: '#646464',
    },
  },
  desktop: {
    primary: {
      ...sbTheme.defaults,
      //  Colors
      fontDefaultColor: '#646464',
      fontHoverColor: '#646464',
      defaultColor: '#fff',
      selectedColor: sbTheme.selected,
      hoverColor: '#f3f2f3',
    },
    secondary: {
      ...sbTheme.defaults,
      fontDefaultColor: '#646464',
      fontHoverColor: '#646464',
      fontSelectedColor: '#646464',
      defaultColor: '#f3f2f3',
      selectedColor: 'rgba(122, 184, 0, 0.8)',
      hoverColor: 'rgba(122, 184, 0, 0.6)',
    },
    tertiary: {
      ...sbTheme.default,
      defaultColor: '#e8e8e8',
      selectedColor: 'rgba(0, 52, 120, 0.8)',
      hoverColor: 'rgba(0, 52, 120, 0.6)',
    },
  },
  transitionDuration: '0.3s',
};
