import { Font } from '@react-pdf/renderer';

import NeoSans from './NeoSans.ttf';
import NeoSansLight from './NeoSans-Light.ttf';
import NeoSansBold from './NeoSans-Bold.ttf';
import MyriadPro from './MyriadPro-Regular.ttf';
import MyriadProBold from './NeoSans-Bold.ttf';
import MinionPro from './MinionPro-Regular.ttf';

Font.register({
  family: 'NeoSans',
  src: NeoSans,
});
Font.register({
  family: 'NeoSansLight',
  src: NeoSansLight,
});
Font.register({
  family: 'NeoSansBold',
  src: NeoSansBold,
});
Font.register({
  family: 'MyriadPro',
  src: MyriadPro,
});
Font.register({
  family: 'MyriadProBold',
  src: MyriadProBold,
});
Font.register({
  family: 'MinionPro',
  src: MinionPro,
});

export default Font;
