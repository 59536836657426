import styled from '@react-pdf/styled-components';

export const ImgWrapper = styled.Image`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const HeadingWrapper = styled.View`
  position: absolute;
  top: 60px;
  left: 30px;
  width: 300px;
  color: white;

  font-family: 'NeoSansLight';
  letter-spacing: -1pt;
`;

export const GraphicWrapper = styled.Image`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  object-fit: cover;
`;
