import styled from '@react-pdf/styled-components';

export const GreenGraphicWrapper = styled.Image`
  height: 131vh;
  width: 70vw;
  object-fit: cover;

  position: absolute;
  top: -43vh;
  left: -10px;
`;

export const BlueGraphicWrapper = styled.Image`
  height: 130vh;
  width: 100vw;
  object-fit: cover;
  object-position: top;

  position: absolute;
  top: -280px;
  left: 80px;
`;

export const ImageWrapper = styled.Image`
  height: 100%;
  width: 63vw;
  object-fit: contain;
  object-position: left;

  position: absolute;
  top: -229px;
  left: -4px;
`;

export const FooterWrapper = styled.View`
  position: absolute;
  top: 92vh;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FooterImgWrapper = styled.View`
  margin: 0 30px;

  height: 70px;
  width: 120px;
`;

export const Title = styled.View`
  position: absolute;
  top: 80px;
  left: 185px;
  width: 300px;

  font-size: 55pt;
  font-family: 'NeoSansLight';
  color: white;
`;

export const ContactCard = styled.View`
  width: 300px;
  position: absolute;
  top: 80px;
  left: 65vw;
`;

export const ContactHeading = styled.View`
  display: flex;
  flex-direction: row;

  color: white;
  font-family: 'MyriadProBold';
  font-size: 8pt;
  margin-bottom: 10px;
`;

export const ContactItem = styled.View`
  display: flex;
  flex-direction: row;

  color: white;
  font-family: 'MyriadPro';
  font-size: 10pt;
  margin-bottom: 10px;
`;

export const ContactItemAlt = styled.View`
  display: flex;
  flex-direction: row;

  color: white;
  font-family: 'MyriadPro';
  font-size: 10pt;
  margin-bottom: 5px;
  margin-left: 30px;
`;
