import balconyColor from './balconyColor';
import balustradeFront from './balustradeFront';
import balustradeLeft from './balustradeLeft';
import balustradeRight from './balustradeRight';
import connection from './connection';
import drainage from './drainage';
import fascia from './fascia';
import toprail from './toprail';
import decking from "./decking"
import diagram from './diagram.png';

export default {
  balconyColor,
  balustradeFront,
  balustradeLeft,
  balustradeRight,
  connection,
  drainage,
  fascia,
  toprail,
  decking,
  diagram,
};
