import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useParams,
} from 'react-router-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from 'styled-components';
import Provider from './context';
import { SERVER_URL } from './constants';
import theme from './theme';
import axios from 'axios';
import { Spin, message } from 'antd';

import { pdf, PDFViewer } from '@react-pdf/renderer';
import Document from './PDF';
import queryString from 'query-string';

const prepPDFQueryString = (qs) => {
  const data = queryString.parse(qs);
  const snapshotBaseUrl = `${SERVER_URL}/api/snapshot`;

  data.snapshots = [
    `${snapshotBaseUrl}/${data.snapshot1}`,
    `${snapshotBaseUrl}/${data.snapshot2}`,
  ];

  return data;
};

const ZipDownload = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState();

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/api/order/${id}`);
        const pdfUrl = response.data.metadata.urls.pdfUrl;
        const pdfQueryString = pdfUrl.substring(pdfUrl.indexOf('?'));
        setData(prepPDFQueryString(pdfQueryString));
      } catch (err) {
        console.log(err);
      }
    })();
  }, [id]);

  if (data && !loaded) {
    const Doc = <Document data={data} />;
    pdf(Doc)
      .toBlob()
      .then(async (blob) => {
        console.log(blob);
        const URL = `${SERVER_URL}/api/generatezip/order/${id}`;

        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
          responseType: 'arraybuffer',
        };

        const data = new FormData();
        data.append('pdf', blob);
        const response = await axios.post(URL, data, config);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'balcony.zip');
        document.body.appendChild(link);
        link.click();
        setLoaded(true);
      });
  }

  return (
    <div
      style={{
        height: 'max-content',
        width: 'max-content',
        position: 'fixed',
        top: '50vh',
        left: '50vw',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {!loaded && <Spin size="small" />}
      <span style={{ paddingLeft: '13px', fontSize: '16px' }}>
        {loaded ? 'Please save your file' : 'Loading Zip File'}
      </span>
    </div>
  );
};

const PDF = () => {
  const location = useLocation();
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState();
  const queries = prepPDFQueryString(location.search);
  const { tkcsid, ...attrs } = queries;

  useEffect(() => {
    (async () => {
      if (!loaded)
        try {
          message.info('Rendering your PDF. This may take a minute...');
          if (tkcsid) {
            const response = await axios.get(
              `${SERVER_URL}/api/configurations/${tkcsid}`
            );
            const { configuration, metadata } = response.data;
            const { user, snapshot1, snapshot2 } = metadata;
            setData({
              ...configuration,
              ...user,
              snapshots: [snapshot1.url, snapshot2.url],
            });
          } else {
            setData(attrs);
          }
        } catch (err) {
          console.log(err);
        }
    })();
  }, [tkcsid, attrs, loaded]);

  if (data && !loaded) {
    setLoaded(true);
  }

  // if (!data) setData(attrs);
  // useEffect(() => {
  //   (() => message.info('Rendering your PDF. This may take a minute...'))();
  // });

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
      }}
    >
      {loaded && (
        <PDFViewer height="100%" width="100%">
          <Document data={data} />
        </PDFViewer>
      )}
    </div>
  );
};

if (process.env.REACT_APP_ENV === 'pdf')
  ReactDOM.render(<PDF />, document.getElementById('root'));
else
  ReactDOM.render(
    <Router>
      <Switch>
        <Route path="/zip/:id" render={() => <ZipDownload />} />
        <Route path="/pdf" render={() => <PDF />} />
        <Route
          exact
          path="/"
          render={() => (
            <Provider>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </Provider>
          )}
        />
      </Switch>
    </Router>,

    document.getElementById('root')
  );
