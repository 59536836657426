import React from 'react';
import { Text, Image as ImageRaw } from '@react-pdf/renderer';
import {
  Left,
  Right,
  RightRow,
  TwoCol,
  SubRight,
  SubLeft,
  Heading,
  ConnectionWrapper,
  StructureWrapper,
  BalustradeWrapper,
  ConnectionHeading,
  StructureHeading,
  BalustradeHeading,
  Item,
  Image,
  ImageAlt,
  HeadingWrapper,
  ItemAlt,
  H3,
} from './page21.styles';
import images from '../../assets/materialChoice';

import PageTemplate from '../../Templates/PageTemplate';

export default ({
  project,
  anchor,
  drainage,
  deckingColor,
  balustradeFront,
  balustradeLeft,
  balustradeRight,
  toprail,
  frontGlassType,
  fasciaOptions,
}) => {
  return (
    <PageTemplate number="2.1" title="Material choice">
      <Left>
        <HeadingWrapper>
          <Heading>Project: {project}</Heading>
        </HeadingWrapper>
        <TwoCol>
          <SubLeft>
            <ImageRaw
              style={{ width: '170px', objectFit: 'contain' }}
              src={images.diagram}
            />
          </SubLeft>
          <SubRight>
            <ConnectionWrapper>
              <ConnectionHeading>
                <Text>Connection</Text>
              </ConnectionHeading>
              <Item>
                <ImageAlt src={images.connection[anchor]} />
                <H3>Connection</H3>
              </Item>
            </ConnectionWrapper>
            <StructureWrapper>
              <StructureHeading>
                <Text>Structure</Text>
              </StructureHeading>
              <Item>
                <Image src={images.decking[deckingColor]} />
                <H3>Deck Finish</H3>
              </Item>
              <Item>
                <Image src={images.drainage[drainage]} />
                <H3>Soffits / drainage</H3>
              </Item>
            </StructureWrapper>
          </SubRight>
        </TwoCol>
      </Left>
      <Right>
        <HeadingWrapper></HeadingWrapper>
        <BalustradeWrapper style={{ marginBottom: '15px' }}>
          <BalustradeHeading>
            <Text>Balustrade</Text>
          </BalustradeHeading>
          <RightRow>
            <ItemAlt>
              {balustradeFront === 'Vertical Bars' ? (
                <Image src={images.balustradeFront['Vertical Bars']} />
              ) : (
                <Image src={images.balustradeFront[frontGlassType]} />
              )}
              <H3>Front</H3>
            </ItemAlt>
            <ItemAlt>
              <Image src={images.toprail[toprail]} />
              <H3>Toprail</H3>
            </ItemAlt>
          </RightRow>
          <RightRow>
            <ItemAlt>
              <Image src={images.balustradeLeft[balustradeLeft]} />
              <H3>Left</H3>
            </ItemAlt>
            <ItemAlt>
              <Image src={images.fascia[fasciaOptions[0]]} />
              <H3>Fascia</H3>
            </ItemAlt>
          </RightRow>
          <RightRow>
            <ItemAlt>
              <Image src={images.balustradeRight[balustradeRight]} />
              <H3>Right</H3>
            </ItemAlt>
            {fasciaOptions.length > 1 && (
              <ItemAlt>
                <Image src={images.fascia[fasciaOptions[1]]} />
                <H3>Fascia</H3>
              </ItemAlt>
            )}
          </RightRow>
        </BalustradeWrapper>
      </Right>
    </PageTemplate>
  );
};
