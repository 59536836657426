import React from 'react';
import { Image, View, Text } from '@react-pdf/renderer';
import { Sidebar } from '../../shared.styles';
import { H3, Item } from './page32.styles';
import data from './data.json';

import PageTemplate from '../../Templates/PageTemplate';

const folders = {
  'Post Fixed': 'post-fix',
  'Cast In': 'cast-in',
};

export default ({ anchor, slabThickness, floorThickness }) => {
  return (
    <PageTemplate number="3.2" title="Connection summary">
      <Image
        style={{ marginTop: '20px' }}
        src={
          folders[anchor]
            ? require(`../../assets/anchorDiagrams/${anchor}.JPG`)
            : require(`../../assets/anchorDiagrams/${folders[anchor]}/Cabs2.0-${slabThickness}-${floorThickness}-A.png`)
        }
      />
      <Sidebar style={{ paddingRight: '20px' }}>
        <H3>{data.title}</H3>
        <Item>
          <View style={{ width: '15px' }}>
            <Text>1. </Text>
          </View>
          <View>
            <Text>
              This detail is based on a concrete slab thickness of{' '}
              {slabThickness}mm with floor finishes of {floorThickness}mm.
            </Text>
          </View>
        </Item>
        {data.points.map((point, i) => (
          <Item>
            <View style={{ width: '15px' }}>
              <Text>{i + 2}.</Text>
            </View>
            <View>
              <Text>{point}</Text>
            </View>
          </Item>
        ))}
      </Sidebar>
    </PageTemplate>
  );
};
