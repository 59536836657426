import styled from '@react-pdf/styled-components';
import { COLORS } from '../../constants';

export const TwoCol = styled.View`
  display: flex;
  flex-direction: row;
`;

export const Left = styled.View`
  width: 310px;
  margin-right: 15px;
`;

export const Right = styled.View`
  width: 160px;
`;

export const Heading = styled.Text`
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const P = styled.Text`
  line-height: 1.6px;
  margin-bottom: 5px;
  font-size: 10px;
`;

export const H2 = styled.Text`
  line-height: 1.6px;
  margin-bottom: 15px;
  font-size: 10px;
  font-weight: 600;
`;

export const ImageCols = styled.View`
  display: flex;
  flex-direction: row;
`;

export const ImgWrapper = styled.Image`
  height: 150px;
  width: 300px;
  object-fit: contain;
`;

export const ImgWrapperAlt = styled.Image`
  height: 280px;
  width: 160px;
  object-fit: contain;
`;

export const Widget = styled.View`
  background: ${COLORS.blue};
  color: white;
  margin-bottom: 15px;
`;
