import styled from '@react-pdf/styled-components';

export const Wrapper = styled.View`
  width: 500px;
`;

export const Item = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

export const ItemLeft = styled.View`
  width: 120px;
`;

export const P = styled.Text`
  font-family: 'MyriadPro';
  font-size: 9pt;
  line-height: 1.2pt;
  margin-bottom: 2px;
  width: 280px;
`;

export const AltP = styled.Text`
  font-family: 'MyriadPro';
  font-size: 9pt;
  line-height: 1pt;
`;
