import React from 'react';
import configuratorJSON from './configurator.json';
import axios from 'axios';
import inputs from './inputs.json';
import validation from './validation';
import queryString from 'query-string';
import { isUuid } from 'uuidv4';
import { prepConfiguratorState, trapeziumArea } from './utils';
import { getSku } from './sku';
import {
  SERVER_URL,
  AUTH_TOKEN,
  ASSET_ID,
  STAGE_ID,
  CAMERAS,
} from './constants';

export const AppContext = React.createContext(null);

class AppProvider extends React.Component {
  handleSetSection = (address, idx) => {
    const updatedState = { ...this.state };
    if (idx) updatedState.data[address[0]].sections[address[1]].selected = idx;
    else updatedState.address = address;
    this.setState(updatedState);
  };

  handleSetInputValue = (address, idx, value) => {
    const updatedState = { ...this.state };

    if (address.length === 2) {
      updatedState.data[address[0]].sections[address[1]].inputs[idx].value =
        value;
    } else if (address.length === 3) {
      updatedState.data[address[0]].sections[address[1]].sections[
        address[2]
      ].inputs[idx].value = value;
    } else return;

    this.setState(updatedState);
    this.setPDFUrl(null);
  };

  setAttribute = (attribute, value) => {
    this.setState((state) => ({
      configuration: { ...state.configuration, [attribute]: value },
      pdfUrl: undefined,
    }));
    this.state.configurator.setConfiguration({
      [attribute]: isUuid(value) ? { assetId: value } : value,
    });
    this.setPDFUrl(null);
  };

  generateSku(configuration) {
    return getSku(configuration);
  }

  setSnapshot = (snapshot) => this.setState({ snapshot: snapshot });

  setPDFUrl = (pdfUrl) => this.setState({ pdfUrl });

  getSnapshots = (api) => {
    const switchCamera = (cameraName) => {
      api.setActiveCamera(
        api.scene.findNode({
          from: STAGE_ID,
          name: CAMERAS.snapshot[cameraName],
        })
      );
      this.state.setAttribute('View', cameraName);
    };
    const switchExternal = () => {
      api.setActiveCamera(
        api.scene.findNode({
          from: STAGE_ID,
          name: CAMERAS.Exterior,
        })
      );
      this.state.setAttribute('View', CAMERAS.Exterior);
    };
    const wait = async (ms) => {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    };
    return async () => {
      const snapshots = [];
      switchCamera('Exterior');
      await wait(1000);
      const exterior = await api.snapshotAsync();
      snapshots.push(exterior);
      switchCamera('Interior');
      await wait(1000);
      const interior = await api.snapshotAsync();
      snapshots.push(interior);

      switchExternal();
      return snapshots;
    };
  };

  handleOrderSave = async (user, callback) => {
    const width = this.state.configuration['Width'] / 1000;
    const height = this.state.configuration['Projection'] / 1000;
    const angleL = this.state.configuration['Left Angle'];
    const angleR = this.state.configuration['Right Angle'];
    axios
      .post(
        SERVER_URL + '/api/pdf',
        {
          user,
          snapshots: await this.state.generateSnapshots(),
          configuration: Object.entries(this.state.configuration).reduce(
            (output, [key, val]) => {
              switch (typeof val) {
                case 'string':
                  output[key] = isUuid(val)
                    ? window.player.sceneGraph.nodes[val].name
                    : val;
                  break;
                case 'object':
                  output[key] = val.assetId?.length
                    ? window.player.sceneGraph.nodes[val.assetId].name
                    : '';
                  break;
                default:
                  output[key] = val;
              }
              return output;
            },
            {}
          ),
        },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        this.setPDFUrl(response.data.pdfUrl);
        callback();
      })
      .catch(console.log);
  };

  componentDidMount() {
    const isDev = process.env.NODE_ENV === 'development';
    window
      .threekitPlayer({
        authToken: AUTH_TOKEN,
        el: document.getElementById('player-root'),
        stageId: STAGE_ID,
        assetId: queryString.parse(window.location.search).wl || ASSET_ID,
        // publishStage: 'draft',
        showAR: true,
      })
      .then((ap) => {
        ap.enableApi('store');
        // ap.tools.removeTool('pan');
        window.player = ap.enableApi('player');
        window.api = ap;
        ap.on(ap.scene.PHASES.PRELOADED, () => {
          const configurator = ap.player.getConfigurator();
          window.configurator = configurator;
          const whiteLabellingAttrs = configurator
            .getAttributes()
            // .filter((attr) => ['Asset', 'String'].includes(attr.type))
            .reduce((output, attr) => {
              if (!['Asset', 'String'].includes(attr.type)) return output;
              output[attr.name] = {
                type: attr.type,
                label: attr.name,
                values:
                  attr.type === 'String'
                    ? attr.values
                    : attr.values
                        .map((val) => {
                          const name = window.player.sceneGraph.nodes[
                            val.assetId
                          ]
                            ? window.player.sceneGraph.nodes[val.assetId].name
                            : val.name;
                          if (name)
                            return {
                              id: name.toLowerCase().replace(' ', '-'),
                              value: val.assetId,
                              label: name,
                            };
                        })
                        .filter((e) => !!e),
              };
              return output;
            }, {});
          let defaultConfig = Object.entries(configurator.configuration).reduce(
            (output, [key, val]) => {
              if (
                typeof val === 'object' &&
                'assetId' in val &&
                (val.assetId.length === 0 ||
                  !whiteLabellingAttrs[key].values.some(
                    (item) => item.value === val.assetId
                  ))
              )
                output[key] = {
                  assetId: whiteLabellingAttrs[key].values[0].value,
                };
              if (
                typeof val === 'string' &&
                whiteLabellingAttrs[key] &&
                (val.length === 0 ||
                  !whiteLabellingAttrs[key].values.includes(val))
              )
                output[key] = whiteLabellingAttrs[key].values[0];
              return output;
            },
            {}
          );
          if (Object.keys(defaultConfig).length > 0)
            configurator.setConfiguration(defaultConfig);

          this.setState({
            configuration: { ...ap.player.getConfigurator().configuration },
            address: [0, 0],
            data: prepConfiguratorState(
              configuratorJSON,
              inputs,
              validation,
              whiteLabellingAttrs
            ),
            handleSetSection: this.handleSetSection,
            handleSetInputValue: this.handleSetInputValue,
            setAttribute: this.setAttribute,
            configurator,
            generateSku: this.generateSku,
            setUser: (user, callback) => {
              this.setState({ ...this.state, user });
              this.handleOrderSave(user, callback);
            },
            setCamera: (camera) => {
              if (this.state.configuration.View === camera) return;
              ap.setActiveCamera(
                ap.scene.findNode({ from: STAGE_ID, name: CAMERAS[camera] })
              );
              this.setAttribute('View', camera);
            },
            generateSnapshots: this.getSnapshots(ap),
            user: {},
            snapshot: {},
            pdfUrl: null,
          });
        });
      });
  }

  componentDidUpdate() {
    process.env.NODE_ENV === 'development' && console.log(this.state);
  }

  render() {
    return (
      <AppContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default AppProvider;
