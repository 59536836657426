import { View, Text, Image as ImageRaw } from '@react-pdf/renderer';
import React from 'react';
import { H2, P } from '../../shared.styles';
import {
  Left,
  Right,
  Heading,
  TableRow,
  Image,
  ImagesRow,
} from './page11.styles';

import data from './data.json';

import PageTemplate from '../../Templates/PageTemplate';

export default (props) => {
  const { snapshot } = props;
  return (
    <PageTemplate number="1.1" title="Balcony specification overview">
      <Left>
        <View>
          <Heading>Project: {props.project}</Heading>
        </View>
        <ImagesRow>
          <Image src={snapshot[0]} style={{ marginRight: '8px' }} />
          <Image src={snapshot[1]} />
        </ImagesRow>
      </Left>
      <Right>
        {data.sections.map((section) => (
          <>
            <H2>{section.title}</H2>
            {section.paragraphs.map((p) => (
              <P>{p}</P>
            ))}
          </>
        ))}
        <View style={{ marginTop: '15px' }}>
          {Object.entries(data.table).map(([key, val], i) => {
            let background = i % 2 === 0 ? 'light' : 'dark';
            return (
              <TableRow background={background}>
                <View style={{ width: '90px' }}>
                  <Text>{key}</Text>
                </View>
                <View style={{ width: '60px' }}>
                  <Text>
                    {props[val]} {data.unit[key]}
                  </Text>
                </View>
              </TableRow>
            );
          })}
        </View>
      </Right>
    </PageTemplate>
  );
};
