import React from 'react';
import { Image } from '@react-pdf/renderer';
import {
  H2,
  P,
  Widget,
  WidgetRow,
  WidgetHeading,
  WidgetItem,
  Chip,
} from '../../shared.styles';
import { Left, Right } from './page91.styles';

import data from './data.json';

import PageTemplate from '../../Templates/PageTemplate';

import Image1 from '../../assets/page91_01.png';

export default ({ width, projection }) => {
  return (
    <PageTemplate number="9.1" title="End user considerations">
      <Left>
        {data.sections.map((section) => (
          <>
            <H2>{section.title}</H2>
            {section.paragraphs.map((p) => (
              <P>{p}</P>
            ))}
          </>
        ))}
        <Widget>
          <WidgetHeading>Your balcony design</WidgetHeading>
          <WidgetRow>
            <WidgetItem style={{ width: '90px', marginRight: '15px' }}>
              Width
            </WidgetItem>
            <WidgetItem>{width / 1000} metres</WidgetItem>
          </WidgetRow>
          <WidgetRow>
            <WidgetItem style={{ width: '90px', marginRight: '15px' }}>
              Projection
            </WidgetItem>
            <WidgetItem>{projection / 1000} metres</WidgetItem>
          </WidgetRow>
          <Chip />
        </Widget>
      </Left>
      <Right>
        <Image src={Image1} />
      </Right>
    </PageTemplate>
  );
};
