import React from 'react';
import { View, Image } from '@react-pdf/renderer';
import { Left, Right, TwoCol, SubLeft, SubRight } from './page102.styles';
import {
  Sidebar,
  WidgetAlt as Widget,
  WidgetHeadingAlt as WidgetHeading,
  WidgetItemAlt as WidgetItem,
} from '../../shared.styles';

import PageTemplate from '../../Templates/PageTemplate';

import Image1 from '../../assets/page102_01.jpg';
import Image2 from '../../assets/page102_02.jpg';
import Image3 from '../../assets/page102_03.jpg';
import Image4 from '../../assets/page102_04.jpg';
import Image5 from '../../assets/page102_05.jpg';

export default () => {
  return (
    <PageTemplate number="10.1" title="Precedent project images & case studies">
      <Left>
        <View>
          <Image src={Image2} />
        </View>
        <TwoCol>
          <SubLeft>
            <Image src={Image3} />
          </SubLeft>
          <SubRight>
            <Image src={Image4} />
          </SubRight>
        </TwoCol>
      </Left>
      <Right>
        <View>
          <Image src={Image5} />
        </View>
      </Right>
      <Sidebar>
        <Widget style={{ marginBottom: '14px' }}>
          <WidgetHeading>Case studies</WidgetHeading>
          <WidgetItem>Southall »</WidgetItem>
          <WidgetItem>London Dock »</WidgetItem>
          <WidgetItem>Greenwich Peninsula (GP116) »</WidgetItem>
          <WidgetItem>Greenwich Peninsula (GP114) »</WidgetItem>
        </Widget>
        <Image src={Image1} />
      </Sidebar>
    </PageTemplate>
  );
};
