import React from 'react';
import { Page, View, Text } from '@react-pdf/renderer';
import {
  BackgroundWrapper,
  UpperWrapper,
  LowerWrapper,
  ImageWrapper,
} from './lastPage.styles';

import Image1 from '../../assets/lastPage_01.png';
import Image2 from '../../assets/lastPage_02.png';

const LastPage = () => {
  return (
    <Page size="A4" orientation="landscape">
      <BackgroundWrapper />
      <UpperWrapper>
        <ImageWrapper src={Image1} />
      </UpperWrapper>
      <LowerWrapper>
        <View style={{ marginRight: '10px' }}>
          <ImageWrapper src={Image2} />
        </View>
        <View style={{ paddingTop: '2px' }}>
          <Text>
            Sapphire Balconies Limited 11 Arkwright Road Reading RG2 0LU
          </Text>
          <Text>
            0344 88 00 553 | sales@sapphire.eu.com | www.sapphire.eu.com
          </Text>
        </View>
      </LowerWrapper>
    </Page>
  );
};
export default LastPage;
