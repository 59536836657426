const sku = {
  Anchor: {
    'Post Fixed': '01',
    'Cast In': '02',
  },
  Shape: {
    Rectangle: '01',
    'Parallelogram Left': '02',
    'Parallelogram Right': '03',
    Trapezium: '04',
    'Angled End Left': '05',
    'Angled End Right': '06',
    'Cornered End Left': '07',
    'Cornered End Right': '08',
  },
  Drainage: {
    'Controlled Drainage': '01',
    'Positive Drainage': '02',
  },
  'Fire Class': {
    'Class A': '01',
    'Class B': '02',
    'Class C': '03',
  },
  'Balustrade Type': {
    'Structural Glass': '01',
    'Vertical Bars': '02',
    'Solid Wall': '03',
    'Module Panel': '04',
    'No Balustrade': '00',
  },
  'Glass Type': {
    Clear: '00001',
    Obscure: '00002',
  },
  'Wall Thickness': {
    '70 mm': '00070',
    '100 mm': '00100',
    '150 mm': '00150',
    '200 mm': '00200',
  },
  Height: {
    '1100 mm': '01100',
    '1200 mm': '01200',
    '1400 mm': '01400',
  },
  Toprail: {
    Capping: '01',
    'Round Handrail': '02',
    'Rectangular Handrail': '03',
  },
  'Glass Fascia': {
    'Fascia Trim': '01',
    'Printed Band': '02',
  },
  'Vertical Bars Fascia': {
    Behind: '01',
    'In Front': '02',
  },
  'Decking Colour': {
    Anthracite: '01',
    'Window Grey': '02',
    'Quartz Grey': '03',
    'Dusty Grey': '04',
    'Sepia Brown': '05',
    'Grey Brown': '06',
    Arbour: '06',
    Driftwood: '07',
    Rose: '08',
    'Beach Plank': '09',
    'Tree House': '10',
    'S1 Old Mahogany Brown': '11',
    'S2 Faded Ebony Grey': '12',
    'S3 Weathered Ash Grey': '13',
    'S4 Sanded Oak Brown': '14',
    'S5 Willow Tree Green': '15',
  },
  'Facade Colour': {
    'Red Brick': '01',
    'Yellow Brick': '02',
    Grey: '03',
    Stone: '04',
    Beige: '05',
  },
  'Balcony Colour': {
    'Light Grey': '01',
    'Dark Grey': '02',
    'Mild Bronze': '03',
    'Dark Bronze': '04',
    Stone: '05',
    White: '06',
  },
};

export const getSku = (configuration) => {
  const getValue = (attr, skuAttr = attr) => sku[skuAttr][configuration[attr]];
  const getNumber = (attr, chars = 5) =>
    ('00000' + configuration[attr]).substr(-chars);

  const anchorType = `A1${getValue('Anchor')}${getNumber(
    `${configuration.Anchor} Slab Thickness`
  )}${getNumber(`${configuration.Anchor} Floor Thickness`)}`;

  const cassetteType = `B1${getValue('Shape')}${getNumber(
    'Left Angle',
    3
  )}${getNumber('Right Angle', 3)}B2${getNumber('Width')}B3${getNumber(
    'Projection'
  )}B4${getNumber('Depth')}B5${getValue('Drainage')}B601${getValue(
    'Fire Class'
  )}`;

  const balustradeType = ['Front', 'Left', 'Right'].reduce((acc, pos, idx) => {
    const type = getValue(`${pos} Type`, 'Balustrade Type');
    acc += `C${idx + 1}${type}`;
    switch (type) {
      case '01':
        acc += getValue(`${pos} Glass Type`, 'Glass Type');
        break;
      case '02':
        acc += '00001';
        break;
      case '03':
        acc += getValue(`${pos} Wall Thickness`, 'Wall Thickness');
        break;
      default:
        acc += '00000';
    }
    if (type !== '00') acc += getValue(`${pos} Height`, 'Height');
    else acc += '00000';
    if (type !== '00' && type !== '03')
      acc += getValue(`${pos} Toprail`, 'Toprail');
    else acc += '00';
    switch (type) {
      case '01':
        acc += `01${getValue(`${pos} Glass Fascia`, 'Glass Fascia')}`;
        break;
      case '02':
        acc += `02${getValue(
          `${pos} Vertical Bars Fascia`,
          'Vertical Bars Fascia'
        )}`;
        break;
      default:
        acc += '0000';
    }
    return acc;
  }, '');

  const colour = `D1${getValue('Decking Colour')}D2${getValue(
    'Facade Colour'
  )}D3${getValue('Balcony Colour')}`;

  return `${anchorType}-${cassetteType}-${balustradeType}-${colour}`;
};
