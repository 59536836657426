import styled from '@react-pdf/styled-components';

export const Left = styled.View`
  width: 180px;
  margin-right: 15px;
`;

export const Right = styled.View`
  width: 320px;
`;

export const P = styled.Text`
  line-height: 1.6px;
  margin-bottom: 5px;
  font-size: 10px;
`;

export const H2 = styled.Text`
  line-height: 1.6px;
  margin-bottom: 15px;
  font-size: 10px;
  font-weight: 600;
`;
