import capping from './capping.jpg';
// import cappingAlt from "./capping-alt.jpg"
import rectangle from './rectangle.jpg';
import round from './round.jpg';

export default {
  'Rectangular Handrail': rectangle,
  'Round Handrail': round,
  Capping: capping,
};
